import React, { Fragment } from "react";
import "./TransHistory.scss";
import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Calendar from "@/commonModule/Calendar/Calendar";
import { checkIfExpired, checkIsTranslatorInspection, handleResetPaginationActive } from "@/util/common";

const TransHistory = ({
  monthTermList,
  onChangeMonthTerm,
  fromDate,
  endDate,
  setFromDate,
  setEndDate,
  orderNo,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  onFileDownload,
  onOrderPaymentForReview,
  onMouseOver,
  onMouseLeave,
}) => {
  return (
    <div className="page-container">
      <div className="page__title">MY 번역</div>
      <small className="page__subtitle">
        변경요청 내역을 조회하고 검수요청 또는 다운로드할 수 있습니다.
      </small>
      <div className="divider"></div>
      <div className="trans-history__notification">
        <p>! 꼭 확인해 주세요.</p>
        <p>
          거래내역은 삭제가 불가능하며, 2018년 이후부터 조회가 가능합니다.
          <br />
          구매한 자료는 구매한 날짜부터 7일간 다운로드 가능합니다.
        </p>
      </div>
      <div className="d-flex">
        <RadioBtn
          name={`monthTerm`}
          btnList={monthTermList}
          className={`mr-20 month-term-radio`}
          callback={onChangeMonthTerm}
        />
        <Calendar
          fromDate={fromDate}
          setFromDate={setFromDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <form
          className="ml-20"
          onSubmit={(e) => {
            e.preventDefault();
            onFetchPage(1);
            handleResetPaginationActive();
          }}
        >
          {" "}
          <div className="input-chaining">
            <input
              className={"form-control"}
              type="text"
              name="orderNo"
              placeholder="주문번호"
              value={orderNo}
              onChange={onChangeFieldValue}
            />
            <div
              className="append"
              onClick={() => {
                onFetchPage(1);
                handleResetPaginationActive();
              }}
            >
              <button
                id="search"
                className={"btn btn-s h-full border-0 bg-kk"}
                type="button"
              >
                조회
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th>주문번호</th>
                <th>내용</th>
                <th>다운로드 기간</th>
                <th>다운로드</th>
                <th>검수신청</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={5}>검색결과가 없습니다.</td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <tr key={`tbody-${i}`}>
                    <td className="line-height-6">
                      <div>{post.orderNo}</div>
                      <div className="mt-5">
                        <small>{`(${post.reqDttm})`}</small>
                      </div>
                    </td>

                    <td className="d-flex justify-content-space-between">
                      <div className="text-align-start line-height-two">
                        <div>{post.transCoverageName}</div>
                        <div className="text-bold">{post.documentNo}</div>
                      </div>
                      <div className="text-align-end line-height-two">
                        <span
                          className={`badge ${pickBadgeStyle(post.transState)}`}
                        >
                          {post.transStateName}
                        </span>
                        <div>
                          <span className="text-bold mr-5">
                            {post.transTypeName}
                          </span>
                          <span>
                            {" "}
                            {post.orgLang}{" "}
                            <em className="fas fa-arrow-right"></em>{" "}
                            {post.transLang}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{post.downEndDate && `~ ${post.downEndDate}`}</td>
                    <td className="text-md">
                      {!checkIfExpired(post.downEndDate) &&
                        post.transState === "E" &&
                        post.files &&
                        post.files.length > 0 &&
                        post.files.map((file) => {
                          if (file.ext.toUpperCase() === "PDF")
                            return (
                              <Link
                                key={post.documentId}
                                to="#"
                                onClick={() =>
                                  onFileDownload(
                                    post.downEndDate,
                                    post.transReqIdx,
                                    post.documentId,
                                    file.name,
                                    "PDF"
                                  )
                                }
                              >
                                <em className="far fa-file-pdf text-danger ml-5"></em>
                              </Link>
                            );
                          if (file.ext.toUpperCase() === "DOCX")
                            return (
                              <Link
                                key={post.documentId}
                                to="#"
                                onClick={() =>
                                  onFileDownload(
                                    post.downEndDate,
                                    post.transReqIdx,
                                    post.documentId,
                                    file.name,
                                    "DOCX"
                                  )
                                }
                              >
                                <em className="far fa-file-word text-primary ml-5"></em>
                              </Link>
                            );
                        })}
                    </td>
                    <td>
                      { post.transState === "E" && !checkIsTranslatorInspection(post.transType) ? (
                        <div className={"d-flex justify-content-center mt-5"}>
                          <button
                            className="btn btn-xs btn-outline__green"
                            onClick={() =>
                              onOrderPaymentForReview(post, "AB", "TRANSLATOR")
                            }
                          >
                            <strong>번역가</strong> 검수
                          </button>
                        </div>
                      ) : null}
                      {  post.transState === "E" && post.transType !== "AC" ? (
                        <div className={"d-flex justify-content-center mt-5"}>
                          <button
                            onMouseOver={() => {
                              onMouseOver(
                                `patent-attorney-${post.transReqIdx}`
                              );
                            }}
                            onMouseLeave={() => {
                              onMouseLeave(
                                `patent-attorney-${post.transReqIdx}`
                              );
                            }}
                            className="btn btn-xs disabled-2 p-relative"

                            /* onClick={() =>
                              onOrderPaymentForReview(
                                post,
                                "AC",
                                "PATENT_ATTORNEY"
                              )
                            } */
                          >
                            <strong>변리사</strong> 검수
                            <span
                              id={`patent-attorney-${post.transReqIdx}`}
                              className="tooltip-title tooltip-title-bottom"
                              style={{ width: "120px", padding: "3px" }}
                            >
                              준비 중입니다.
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

function pickBadgeStyle(transState) {
  switch (transState) {
    case "H":
      return "badge-secondary";
    case "A":
      return "badge-info";
    case "W":
      return "badge-warning";
    case "P":
      return "badge-primary";
    case "E":
      return "badge-success";
    default:
      break;
  }
}

export default TransHistory;
