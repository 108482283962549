import { Link } from "react-router-dom";
import React from "react";

const UserInfo = ({ userInfo = {}, onChangeFieldValue, onSubmit, errors }) => {
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="card w-full">
        <div className="card__header pd-0">Profile</div>
        <div className="card__main">
          <div className="form-group mb-5">
            <label>이메일 *</label>
            <div className="input-chaining">
              <input
                className={"form-control disabled"}
                type="text"
                name="email"
                placeholder="이메일을 입력해주세요."
                value={userInfo.email}
                onChange={onChangeFieldValue}
                disabled
              />
            </div>
          </div>
          <div className="form-group radio">
            <label>종류</label>
            <div className="mt-5 mb-10 ">
              <label className="c-radio">
                <input
                  type="radio"
                  name="memberType"
                  value="2"
                  checked={userInfo.memberType === 2}
                  disabled
                />{" "}
                <span className="fa fa-circle" />
                기업
              </label>
              <label className={"c-radio"}>
                <input
                  type="radio"
                  name="memberType"
                  value="1"
                  checked={userInfo.memberType === 1}
                  disabled
                />{" "}
                <span className="fa fa-circle" />
                개인
              </label>
            </div>
          </div>

          <div className="form-group mb-10">
            <label>이름</label>
            <div className="input-chaining">
              {" "}
              <input
                className={`form-control disabled`}
                type="text"
                name="name"
                value={userInfo.name}
                disabled
              />
            </div>
          </div>
          <div className="form-group mb-15">
            <label>연락가능한 번호</label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="text"
                name="hphone"
                maxLength="12"
                value={userInfo.hphone}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.hphone}</p>
          </div>
        </div>
        <div className="card__footer align-items-end">
          <button className="btn btn-s mb-5">회원정보 수정</button>

          <span>IP KINGKONG을 더 이상 사용하지 않는다면</span>
          <Link to="/my/quit">회원탈퇴 바로가기</Link>
        </div>
      </div>
    </form>
  );
};

export default UserInfo;
