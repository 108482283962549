import React from "react";
import RegistSuccess from "./fragments/RegistSuccess/RegistSuccess";
import RegistFail from "./fragments/RegistFail/RegistFail";
import Loader from "@/commonModule/Loader/Loader";
/* 
사용자 이메일에서 링크를 클릭했을 때, /join/regist/auth의 url로 진입하게 되는데, 여기서 토큰을 서버에 보내 결과에 따라 성공/실패 페이지를 렌더링 */
const RegistResult = ({ isLoading, isVerified }) => {
  return isLoading ? (
    <Loader />
  ) : isVerified ? (
    <RegistSuccess />
  ) : (
    <RegistFail />
  );
};

export default RegistResult;
