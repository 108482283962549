import React from "react";

const ChangePassword = ({
  passwordInfo,
  onChangeFieldValue,
  errors,
  onSubmit,
}) => {
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="card w-full">
        <div className="card__header pd-0">Account</div>
        <div className="card__main">
          <div className="form-group mb-15">
            <label>현재 비밀번호</label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="password"
                name="currentPassword"
                placeholder="현재 비밀번호"
                value={passwordInfo.currentPassword}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.currentPassword}</p>
          </div>
          <div className="form-group mb-15">
            <label>새 비밀번호</label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="password"
                name="newPassword1"
                placeholder="새 비밀번호(8-32자리)"
                value={passwordInfo.newPassword1}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.newPassword1}</p>
          </div>
          <div className="form-group mb-15">
            <label>새 비밀번호 확인</label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="password"
                name="newPassword2"
                placeholder="새 비밀번호 확인"
                value={passwordInfo.newPassword2}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.newPassword2}</p>
          </div>
        </div>
        <div className="card__footer align-items-end">
          <button className="btn btn-s mb-5">비밀번호 변경</button>
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
