import React, { Component } from "react";
import PreviewWrapper from "./PreviewWrapper";
import Swal from "sweetalert2";
import { calculateCash, httpRequest, TRANS_CODE_DATA } from "@/util/common";
import { connect } from "react-redux";

class PreviewWrapperContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.chosen !== prevProps.chosen) {
      const chosen = { ...this.props.chosen };
      Object.keys(chosen).forEach((key) => {
        const obj = chosen[key];
        obj.costUnit = TRANS_CODE_DATA[obj.orgLang + obj.transLang]["unitCost"];
        obj.transType = "AA";
        obj.chargePoint = calculateCash(
          obj.charCount,
          obj.orgLang,
          obj.transLang,
          "AA"
        );
      });
      this.setState({
        chosen,
      });
    }
  }

  render() {
    const { onClickClosePreview } = this.props;
    const { chosen } = this.state;

    return (
      <PreviewWrapper
        chosen={chosen}
        onClickClosePreview={onClickClosePreview}
        onClickTransType={this.handleClickTransType}
        onAddWishList={this.handleAddWishList}
      />
    );
  }

  /**
   * 번역비용 금액 라디오버튼 BASIC/SPECIAL/PREMIUM 중 선택하기
   * @param {*} e
   * @param {*} post
   */
  handleClickTransType = (e, post) => {
    const chosen = { ...this.state.chosen };
    chosen[post.documentId].transType = e.target.value;
    chosen[post.documentId].chargePoint = e.target.dataset.chargepoint;
    this.setState({ chosen });
  };

  /**
   * 보관함 추가 버튼 클릭 시 보관함추가 및 Alert 실행하기.
   */
  handleAddWishList = async () => {
    const { auth } = this.props;
    const cartItems = Object.values(this.state.chosen).map((doc) => ({
      ...doc,
      transCode: doc.orgLang + doc.transLang,
      transUnit: "CHAR",
      transState: "A", // 번역 상태 (A: 번역요청, P: 진행중, E:진행완료)
      transReqType: "P", // 번역 요청 유형 (P: 특허공보, N: 사용자문서, O: 기타)
    }));

    try {
      /* 보관함 추가 */
      await httpRequest.post(`/cart/${auth.user.idx}`, cartItems);
      Swal.fire({
        icon: "success",
        title: "보관함에 추가되었습니다.",
        showCancelButton: true,
        cancelButtonText: "닫기",
        confirmButtonText: "보관함으로 이동",
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.history.push("/my/wishList");
        }
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "보관함 추가 실패",
        text: "보관함 추가에 실패하였습니다.",
      });
    }
  };
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(PreviewWrapperContainer);
