import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import BoardNews from "./BoardNews";

class BoardNewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTitle: "",
      boardNews: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  /**
   * 서버에서 boardNews 정보 받아오기
   */
  componentDidMount() {
    this.fetchBoardNews(1);
  }

  render() {
    const { searchTitle, boardNews, postsPerPage, total } = this.state;

    return (
      <BoardNews
        searchTitle={searchTitle}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={boardNews}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchBoardNews}
      />
    );
  }

  /**
   * 서버에서 새소식 정보 받아오기
   */
  fetchBoardNews = async (number) => {
    const { searchTitle, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `board/news?page=${number}&size=${postsPerPage}&title=${searchTitle}`
      );

      this.setState({ ...this.state, boardNews: items, total });
    } catch (err) {
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 검색타이틀 인풋 변경
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default BoardNewsContainer;
