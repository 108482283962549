import React from "react";
import "./OrderPayment.scss";
import ChargeInfoContainer from "@/commonModule/ChargeInfo/ChargeInfoContainer";
import { commafy, TRANS_CODE_DATA } from "@/util/common";

const OrderPayment = ({
  previewDocs,
  preTotalPoint,
  hphone,
  email,
  holdingPoint,
  usePoint,
  netTotalPoint,
  totalWithVAT,
  onChangeFieldValue,
  onChangeUsePoint,
  onFocusUsePoint,
  onBlurUsePoint,
  onUseAllPoint,
  isLoading,
  errors,
  onPayment,
}) => {
  return (
    <div className="page-container order-payment">
      <div className={"page__header"}>
        <div className="page__title">주문 결제</div>
      </div>
      <div className={"page-contents"}>
        <div className={"d-flex w-full mb-20"}>
          <table className="table table-bordered w-full">
            <thead>
              <tr>
                <th className="text-center">문헌명</th>
                <th style={{ width: 120 }} className="text-align-center">
                  번역 범위
                </th>
                <th style={{ width: 120 }} className="text-align-center">
                  번역 종류
                </th>
                <th style={{ width: 180 }} className="text-align-center">
                  캐릭터수
                </th>
                <th style={{ width: 200 }} className="text-align-center">
                  번역 유형
                </th>
                <th style={{ width: 150 }} className="text-align-center">
                  캐시
                </th>
              </tr>
            </thead>
            <tbody>
              {previewDocs &&
                previewDocs.length > 0 &&
                previewDocs.map((doc, i) => (
                  <tr key={`orderPayment-${i}`}>
                    <td className="text-align-center">{doc.documentName}</td>
                    <td className="text-align-center">
                      {doc.transCoverage === "F" ? "전체" : "명칭+요약+청구항"}
                    </td>
                    <td className="text-align-center">
                      {TRANS_CODE_DATA[doc.orgLang + doc.transLang]["typeName"]}
                    </td>
                    <td className="text-align-center">
                      {
                        TRANS_CODE_DATA[doc.orgLang + doc.transLang][
                          "orgLangName"
                        ]
                      }{" "}
                      {commafy(doc.charCount)}
                      {doc.orgLang === "EN" ? "단어" : "자"}
                    </td>
                    <td className="text-align-center">
                      {doc.transType === "AA"
                        ? "AI번역+휴먼보정"
                        : doc.transType === "AB"
                        ? "번역가 검수"
                        : "변리사 검수"}
                    </td>
                    <td className="text-align-center">
                      {commafy(doc.chargePoint)} 캐시
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="card b pd-0">
          <div className="card__header mb-0 bg-light">
            <label className={"key-info-font"}>주문인 정보</label>
            <div className={"mt-10 text-normal"}>
              진행상황 및 번역 완료 안내를{" "}
              <span className={"text-bold text-danger"}>이메일</span> 또는{" "}
              <span className={"text-bold text-danger"}>문자</span>로
              알려드립니다. 정확하게 기재해주세요.
            </div>
          </div>
          <div className="card__main flex-direction-row justify-content-space-evenly">
            <div>
              <div
                className={
                  "d-flex align-items-center justify-content-space-around"
                }
              >
                <label className={"mr-15 text-bold"}>휴대폰번호</label>
                <div>
                  <input
                    className={"form-control"}
                    style={{ width: 250 }}
                    name="hphone"
                    value={hphone}
                    onChange={onChangeFieldValue}
                  />
                </div>
              </div>
              {errors && (
                <p className="validation-message mt-5">{errors.hphone}</p>
              )}
            </div>
            <div>
              {" "}
              <div
                className={
                  "d-flex align-items-center justify-content-space-around"
                }
              >
                <label className={"mr-15 text-bold"}>이메일</label>
                <div>
                  <input
                    className={"form-control"}
                    style={{ width: 400 }}
                    name="email"
                    value={email}
                    onChange={onChangeFieldValue}
                  />
                </div>
              </div>
              {errors && (
                <p className="validation-message mt-5">{errors.email}</p>
              )}
            </div>
          </div>
        </div>
        <div className="card b pd-0 payment-amount">
          <div className="card__header mb-0 bg-light">
            <label className={"key-info-font"}>결제금액</label>
          </div>
          <div className="card__main flex-direction-row">
            <div className={"payment-amount-left"}>
              <div className={"key-info-font payment-amount-left__order"}>
                <label>주문금액</label>
                <label>{commafy(preTotalPoint)} 캐시</label>
              </div>
              <div className={"info-font d-flex justify-content-start mb-30"}>
                <label className={"d-flex align-items-flex-start"}>
                  보유 킹콩캐시 : {commafy(holdingPoint)}{" "}
                  <img src="/images/kcash_ico-16x16.png" />
                </label>
              </div>
              <div className={"info-font d-flex justify-content-end"}>
                <div className={"d-flex align-items-center"}>
                  <span className={"mr-5"}>
                    <input
                      className="form-control text-align-end"
                      type="text"
                      name="usePoint"
                      value={usePoint}
                      onChange={onChangeUsePoint}
                      onFocus={onFocusUsePoint}
                      onBlur={onBlurUsePoint}
                    />
                  </span>
                  캐시{" "}
                  <button
                    className={"btn btn-xs d-inline-flex mr-5 ml-5"}
                    onClick={onUseAllPoint}
                  >
                    전액사용
                  </button>{" "}
                  <small>(최소 1 단위 입력가능)</small>
                </div>
              </div>
            </div>
            <div
              className={
                "payment-amount-right d-flex flex-direction-column w-half"
              }
            >
              <div className={"key-info-font payment-amount-left__payment"}>
                <label>결제정보</label>
              </div>
              <div
                className={
                  "info-font d-flex justify-content-space-between mb-5"
                }
              >
                <label>주문금액</label>
                <label>{commafy(preTotalPoint)}</label>
              </div>
              <div
                className={
                  "info-font d-flex justify-content-space-between pb-30 border-bottom border-dark"
                }
              >
                <label>캐시사용</label>
                <label>(-) {commafy(usePoint)}</label>
              </div>
              <div
                className={
                  "key-info-font d-flex justify-content-space-between mt-15"
                }
              >
                <label>결제금액</label>
                <label>{commafy(netTotalPoint)}원</label>
              </div>
              <div className={"mt-10 text-align-right"}>
                <small>
                  <em className="fa fa-info-circle"></em> 1캐시 = 1원
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="card b pd-0 payment-total">
          <div className="card__main flex-direction-row justify-content-center align-items-end">
            <div className={"text-align-center"}>
              <div>결제금액</div>
              <div className={"payment-total__money"}>
                {commafy(netTotalPoint)}원
              </div>
            </div>
            <div className={"payment-total__operator"}>+</div>
            <div className={"text-align-center"}>
              <div>부가가치세 10%</div>
              <div className={"payment-total__money"}>
                {commafy(Math.floor(netTotalPoint * 0.1))}원
              </div>
            </div>
            <div className={"payment-total__operator"}>=</div>
            <div className={"text-align-center payment-total__money text-kk"}>
              {commafy(totalWithVAT)}원
            </div>
          </div>
        </div>
        <div className="card b pd-0 payment-type">
          <div className="card__header mb-0 bg-light">
            <label className={"key-info-font"}>결제방법</label>
          </div>
          <div className="card__main pl-30 pr-30">
            <div className="c-radio mb-20 info-font">
              <label className={"d-flex align-items-center"}>
                <input
                  type="radio"
                  className="check"
                  name="creditCard"
                  defaultChecked={true}
                />
                <span className={"fa fa-check"} />
                <span className={""}>신용카드</span>
              </label>
            </div>
            <div className={"info-font mb-20"}>안전결제(ISP)/ 안심클릭</div>
            <div className={"info-font"}>
              결제 시 주민등록번호, 비밀번호 등의 개인정보를 입력하지 않고,
              사전에 미리 설정해 둔 인터넷 안전결제(ISP) 비밀번호만 입력하여
              결제하도록 하여 개인정보 유출 및 카드 도용 등을 방지해주는 서비스
              입니다.
            </div>
          </div>
        </div>
        <div className={"d-flex justify-content-space-between"}>
          <ChargeInfoContainer className={"mt-20"} />
          <div className={"mt-30"}>
            <button
              className={`btn btn-xxl ${isLoading ? "disabled" : ""}`}
              onClick={onPayment}
            >
              <em className="fa fa-check mr-5"></em>
              <strong> 결제 하기</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPayment;
