import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./RegistConfirm.scss";

const RegistConfirm = ({ location, history }) => {
  const email = location.search.split("?email=")[1];
  useEffect(() => {
    if (!email) {
      history.push("/");
    }
  }, []);

  return (
    <section className="regist-confirm">
      <div className="regist-confirm__logo">
        <i className="fas fa-envelope"></i>
      </div>
      <div className="regist-confirm__title">이메일 주소 인증</div>
      <div className="regist-confirm__subtitle">
        <span>이제 거의 완료되었습니다.</span>
        <span>{`인증메일이 ${email}(으로) 발송되었습니다.`}</span>{" "}
      </div>
      <div>
        <span>
          이메일에 기재된 링크를 통해 인증하면 IP KINGKONG 회원 가입 절차가
          완료됩니다.
        </span>
        <span>
          이메일을 못 받으셨나요?{" "}
          <Link
            className="text-kk text-bold"
            to={{ pathname: "/sendAuthMail", query: { email } }}
          >
            인증 이메일 재발송하기
          </Link>
        </span>
      </div>
      <Link to="/" className="btn btn-xl">
        <span>홈으로 가기</span>
      </Link>
    </section>
  );
};

export default RegistConfirm;
