import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppContainer } from "react-hot-loader";
import App from "./App";
import store from "./store";
import { IntlProvider } from "react-intl";
import krKo from "./locale/kr-ko.json";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundaryForLifecycle from "@/commonModule/ErrorBoundaryForLifecycle";

// ip_config 테스트서버 분기
/* console.log(ip_config); */

const render = (Component) =>
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <IntlProvider locale={"ko"} messages={krKo}>
          <ErrorBoundaryForLifecycle>
            <BrowserRouter>
              <Component />
            </BrowserRouter>
          </ErrorBoundaryForLifecycle>
        </IntlProvider>
      </AppContainer>
    </Provider>,
    document.getElementById("root")
  );

render(App);

// Webpack Hot Module Replacement API 부분
if (module.hot) module.hot.accept("./App", () => render(App));
