import TransPreview from "@/commonModule/TransPreview/TransPreview";
import React from "react";
import { Link } from "react-router-dom";
import "./PreviewWrapper.scss";

const PreviewWrapper = ({
  chosen,
  onClickClosePreview,
  onClickTransType,
  onAddWishList,
}) => {
  return (
    <div className={"preview-wrapper"}>
      <TransPreview
        chosen={chosen}
        savePct={"80"}
        onClickTransType={onClickTransType}
      />
      <div className={"preview-wrapper__append"}>
        <div className={"d-flex justify-content-end"}>
          <button className={"btn btn-s"} onClick={onAddWishList}>
            <em className="fa fa-cart-plus mr-5" />
            보관함
          </button>
          <button className={"btn btn-s"}>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/payment/orderPayment",
                query: {
                  chosen,
                  transReqType: "P",
                },
              }}
            >
              <em className="fa fa-check mr-5" />
              주문하기
            </Link>
          </button>
          <button
            className={"btn btn-s bg-danger"}
            onClick={onClickClosePreview}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewWrapper;
