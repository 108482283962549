import React, { Fragment } from "react";
import NavbarContainer from "@/commonModule/Navbar/NavbarContainer";
import FooterContainer from "@/commonModule/Footer/FooterContainer";

const LandingLayout = (props) => {
	return (
		<Fragment>
			<NavbarContainer {...props} />
			{props.children}
			<FooterContainer {...props} />
		</Fragment>
	);
};

export default LandingLayout;
