import React, { Component, Fragment } from "react";
import PatentPublication from "./PatentPublication";
import { COUNTRY_TYPE_INFO } from "@/serviceModule/PatentPublication/PatentPublicationInfo";
import { convertIntoLangName, httpRequest } from "@/util/common";
import { connect } from "react-redux";
import CommonModal from "@/commonModule/Modal/CommonModal";
import PreviewWrapperContainer from "./fragments/PreviewWrapper/PreviewWrapperContainer";

class PatentPublicationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPreview: false,
      countryEnum: "KR",
      numbers: "",
      patentList: [],
      chosen: {},
      error: "",
      isLoading: false,
    };

    this.countryTypeList = Object.keys(COUNTRY_TYPE_INFO).map((key) => {
      const obj = COUNTRY_TYPE_INFO[key];
      return {
        label: obj.nameKo,
        value: obj.countryType,
        defaultChecked: key === "KR",
      };
    });
  }

  componentWillUnmount() {
    document.body.style.overflow = "unset";
  }

  render() {
    const {
      openPreview,
      countryEnum,
      numbers,
      patentList,
      chosen,
      error,
      isLoading,
    } = this.state;
    return (
      <Fragment>
        <PatentPublication
          countryTypeList={this.countryTypeList}
          patentList={patentList}
          hasChosen={Object.values(chosen).length}
          onClickPreview={this.handleClickPreview}
          countryEnum={countryEnum}
          onChangeCountryEnum={this.handleCountryEnum}
          numbers={numbers}
          onChangeFieldValue={this.handleChangeFieldValue}
          onSearch={this.handleSearch}
          error={error}
          onCheckbox={this.handleCheckbox}
          onSelectOption={this.handleSelectOption}
          isLoading={isLoading}
        />

        <CommonModal
          id={"transPreview"}
          isOpen={openPreview}
          title={"번역 비용"}
          className={"bg-primary"}
          style={{ width: 1100 }}
          onClickClosePreview={this.handleClickClosePreview}
        >
          <PreviewWrapperContainer
            chosen={chosen}
            onClickClosePreview={this.handleClickClosePreview}
            history={this.props.history}
          />
        </CommonModal>
      </Fragment>
    );
  }

  /**
   * 라디오버튼 선택 시 국가타입 변경하기
   * @param {*} e
   */
  handleCountryEnum = (e) => {
    this.setState({
      openPreview: false,
      countryEnum: e.target.value,
      numbers: "",
      patentList: [],
      chosen: {},
      error: "",
    });
  };

  /**
   * 검색 textArea 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * 번호검색 요청 전 유효성검사하기
   * @returns
   */
  validate = () => {
    const { numbers } = this.state;
    let validated = true;
    let error = "";
    const checkNumberCount = (numbers, count) => {
      numbers = numbers.replace(/^(\s|\n)*|(\s|\n)*$/g, "");
      numbers = numbers.replace(/(\s|\n){2,}/g, " ");
      let numberArray = numbers.split(" ").join("\n").split("\n");

      if (numberArray.length > count) {
        return false;
      } else {
        return true;
      }
    };
    if (!checkNumberCount(numbers, 5)) {
      error = "문헌번호는 최대 5개까지만 검색됩니다.";
      validated = false;
    }
    if (!numbers) {
      error = "입력된 번호가 없습니다.";
      validated = false;
    }

    this.setState({ error });
    return validated;
  };

  /**
   * 번호검색 요청하기
   * @param {*} e
   */
  handleSearch = async (e) => {
    if (this.validate()) {
      const { countryEnum, numbers } = this.state;
      const searchObj = {
        countryEnum: [countryEnum],
        number: numbers,
        docTypeEum: ["A", "B", "U", "Y", "E", "P", "S"],
        numberTypeEnum: ["AN", "RN", "PN"],
        type: "MULTIFORMAT",
      };
      this.setState({ isLoading: true });
      try {
        const {
          data: { patents: patentList },
        } = await httpRequest.post(`patent/numberSearch`, searchObj);
        if (!patentList || patentList.length === 0)
          throw new Error("검색된 결과가 없습니다.");
        this.setState({ patentList, chosen: {}, error: "" });
      } catch (err) {
        this.setState({
          error: err.message,
          patentList: [],
          chosen: {},
        });
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  /**
   * 번역비용 계산하기
   */
  handleClickPreview = async () => {
    /* 글자 수 조회를 위한 docList 객체 생성 */
    const docList = Object.values(this.state.chosen).map((patent) => ({
      coverage: {
        [patent.documentId]: patent.transCoverage,
      },
      transLang: patent.orgLang + patent.transLang,
    }));

    try {
      /* 문헌 글자 수 조회하기 */
      const { data } = await httpRequest.post(`patent/count`, { docList });

      /* 문헌 글자 수 chosen에 추가하기 */
      const chosen = { ...this.state.chosen };
      data.forEach((countInfo) => {
        chosen[countInfo.documentId].charCount = countInfo.count;
      });

      this.setState({ chosen }, () => {
        this.handleClickOpenPreview();
      });
    } catch (err) {
      this.props.history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 비용확인 팝업 열기
   */
  handleClickOpenPreview = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      openPreview: true,
    });
  };

  /**
   * 비용확인 팝업 닫기
   */
  handleClickClosePreview = () => {
    document.body.style.overflow = "unset";
    this.setState({
      openPreview: false,
    });
  };

  /**
   * 번호검색결과 중 체크박스(라디오버튼) 선택하기
   * @param {*} post
   */
  handleCheckbox = (post, i) => {
    const chosen = {};
    chosen[post.documentId] = {
      documentDate: post.documentDate,
      documentId: post.documentId,
      documentNo: post.patentNumber,
      documentName: `${post.patentNumber}(${post.date})`,
      orgLang: convertIntoLangName(this.state.countryEnum).name,
      transLang: document.getElementById(`selectTransCode${i}`).value,
      /*  "F" -> 전문, "P" -> 명칭+요약+청구항 */
      transCoverage: document.getElementById(`coverageFull${i}`).checked
        ? document.getElementById(`coverageFull${i}`).value
        : document.getElementById(`coverageKeyField${i}`).value,
    };

    this.setState({ chosen });
  };

  /**
   * 번호검색결과 중 라디오버튼(전문/명칭+요약+청구항) 선택하기
   * 번호검색 결과 중 번역언어(영,중,일 / 한) 선택하기
   */
  handleSelectOption = (post, i) => {
    const chosen = { ...this.state.chosen };
    let isExist = Object.keys(chosen).indexOf(post.documentId) > -1;
    if (isExist) {
      chosen[post.documentId].transLang = document.getElementById(
        `selectTransCode${i}`
      ).value;
      chosen[post.documentId].transCoverage = document.getElementById(
        `coverageFull${i}`
      ).checked
        ? document.getElementById(`coverageFull${i}`).value
        : document.getElementById(`coverageKeyField${i}`).value;

      this.setState({ chosen });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(PatentPublicationContainer);
