import React from "react";
import { Link } from "react-router-dom";

const RecoverSuccess = () => {
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-check" />
          </div>
          <div className="round-text-bold text-lg">
            <p className={"text-align-center"}>
              IP KINGKONG 비밀번호 변경이 완료되었습니다.
            </p>
          </div>
        </div>

        <div className="result-page__contents-direction">
          <Link to={"/login"} className="btn btn-primary btn-xxl">
            로그인
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RecoverSuccess;
