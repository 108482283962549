import { clientLogout } from "@/commonSlices/authSlice";
import { convertDateFormat, httpRequest } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import PointHistory from "./PointHistory";

class PointHistoryContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.state = {
      pointType: "",
      fromDate: d,
      endDate: new Date(),
      histories: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchPointHistory(1);
  }

  render() {
    const { pointType, fromDate, endDate, histories, postsPerPage, total } =
      this.state;
    return (
      <PointHistory
        pointType={pointType}
        fromDate={fromDate}
        endDate={endDate}
        onChangeFieldValue={this.handleChangeFieldValue}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        currentPosts={histories}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchPointHistory}
      />
    );
  }

  /**
   * 서버에서 캐시이용내역 정보 받아오기
   */
  fetchPointHistory = async (number) => {
    const { pointType, fromDate, endDate, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { total, items },
      } = await httpRequest.get(
        `point/history?page=${number}&size=${postsPerPage}&from=${convertDateFormat(
          fromDate
        )}&to=${convertDateFormat(endDate)}&type=${pointType}`
      );

      this.setState({ histories: items, total });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 포인트타입 셀렉트박스 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ endDate: date });
  };
}

export default connect(null, { clientLogout })(PointHistoryContainer);
