import React, { useState } from "react";
import "./Privacy.scss";
import {PRIVACY_CONTENTS} from "@/commonModule/Privacy/privacy_constant";

const Privacy = ({ showPrivacy, togglePrivacy }) => {
  const [ contents, setContents ] = useState(PRIVACY_CONTENTS.v4_0);
  const showHideClassName = showPrivacy
    ? `privacy-modal d-flex`
    : `privacy-modal display-none`;

  const changeVersion = (e) => {
    setContents(PRIVACY_CONTENTS[e.target.value]);
  }

  return (
    <div className={showHideClassName} onClick={togglePrivacy}>
      <section
        className="privacy-modal-contents"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="privacy-modal-contents__header">
          <span>개인정보처리방침</span>
          <div
            className="privacy-modal-contents__close-btn"
            onClick={togglePrivacy}
          >
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="privacy-modal-contents__main">
          <h3 className="mb-20">
            <span>(주)워트인텔리전스 개인정보 처리방침</span>
            <select className="version-selector" onChange={changeVersion}>
              <option value="v3_0">v 3.0</option>
              <option value="v4_0" selected>v 4.0</option>
            </select>
          </h3>
          <div dangerouslySetInnerHTML={{ __html: contents }} />
        </div>
      </section>
    </div>
  );
};

export default Privacy;
