import React from "react";
import { Link } from "react-router-dom";

const Error = ({ location }) => {
  const code = location.search && location.search.split("?code=")[1];
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-wrench" />
          </div>
          <div className="round-text-bold text-lg">
            <p className={"text-align-center"}>{code || 500}</p>
            <p className={"text-align-center"}>페이지 오류 안내</p>
          </div>
        </div>
        <div className="result-page__contents-sub">
          <p className="">서비스 이용에 불편을드려 죄송합니다.</p>
          <p className="">잠시 후 다시 이용해주세요.</p>
        </div>
        <div className="result-page__contents-direction">
          <Link to={"/"} className="btn btn-primary btn-xxl">
            홈
          </Link>
          <Link to={"/login"} className="btn btn-primary btn-xxl">
            로그인
          </Link>
          <Link to={"/join/form"} className="btn btn-primary btn-xxl">
            가입하기
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Error;
