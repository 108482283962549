const SUB_MENU_LIST = {
  userInfo: [
    { name: "회원정보", url: "/my/userInfo" },
    { name: "비밀번호 변경", url: "/my/changePassword" },
  ],
  boardInfo: [
    { name: "새소식", url: "/board/news" },
    { name: "FAQ", url: "/board/faq" },
  ],
};

export const SUB_MENU_INFO = {
  ["/my/userInfo"]: {
    title: "내 정보",
    desc: "회원정보와 비밀번호를 변경 및 조회할 수 있습니다.",
    menuList: SUB_MENU_LIST.userInfo,
    menuTitle: "내 정보",
  },
  ["/my/changePassword"]: {
    title: "내 정보",
    desc: "회원정보와 비밀번호를 변경 및 조회할 수 있습니다.",
    menuList: SUB_MENU_LIST.userInfo,
    menuTitle: "내 정보",
  },
  ["/my/quit"]: {
    title: "내 정보",
    desc: "회원정보와 비밀번호를 변경 및 조회할 수 있습니다.",
    menuList: SUB_MENU_LIST.userInfo,
    menuTitle: "내 정보",
  },
  ["/board/news"]: {
    title: "도움말",
    desc: "IP KINGKONG의 새소식과 자주묻는 질문을 확인할 수 있습니다.",
    menuList: SUB_MENU_LIST.boardInfo,
    menuTitle: "도움말",
  },
  ["/board/faq"]: {
    title: "도움말",
    desc: "IP KINGKONG의 새소식과 자주묻는 질문을 확인할 수 있습니다.",
    menuList: SUB_MENU_LIST.boardInfo,
    menuTitle: "도움말",
  },
};
