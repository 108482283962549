import LoginContainer from "@/serviceModule/Login/LoginContainer";
import React from "react";
import "./LoginModal.scss";

const LoginModal = ({ show, onShowModal }) => {
  const showHideClassName = show
    ? `login-modal display-block`
    : `login-modal display-none`;

  return (
    <div className={showHideClassName} onClick={onShowModal}>
      <section
        className="login-modal-contents"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="login-modal-contents__header">
          <img src="/images/logo.png" alt="logo" />
        </div>
        <LoginContainer onShowModal={onShowModal} />
      </section>
    </div>
  );
};

export default LoginModal;
