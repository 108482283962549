import React from "react";
import { Link } from "react-router-dom";

const OrderCancel = () => {
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-times-circle" />
          </div>
          <div className="round-text-bold text-lg">
            번역 신청이 취소되었습니다.
          </div>
        </div>
        <div className="result-page__contents-direction">
          <Link to={"/my/transHistory"} className="btn btn-primary btn-xxl">
            My 번역
          </Link>
          <Link to={"/"} className="btn btn-primary btn-xxl">
            홈
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OrderCancel;
