import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import BoardNewsDtl from "./BoardNewsDtl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class BoardNewsDtlContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      attachFile: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchBoardNewsDtl();
  }
  render() {
    const { content, attachFile, isLoading } = this.state;
    return (
      <BoardNewsDtl
        post={content}
        attachFile={attachFile}
        onFileDownload={this.handleFileDownload}
        isLoading={isLoading}
      />
    );
  }

  /**
   * 서버에서 새소식 상세정보 받아오기
   */
  fetchBoardNewsDtl = async () => {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    try {
      const {
        data: { content, attachFile },
      } = await httpRequest.get(`board/news/${id}`);
      this.setState({ content, attachFile });
    } catch (err) {
      history.push(`/error?code=${err.response.status}`);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  /**
   * 첨부파일 다운로드 받기
   * @param {*} fileIdx
   * @returns
   */
  handleFileDownload = async (fileIdx, orgFileName) => {
    const url = `${ip_config.baseUrl}/board/file/${fileIdx}`;
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.download = orgFileName;
      document.body.append(link);
      link.click();
    } catch (err) {
      toast.error("다운로드 실패하였습니다.");
    }
  };
}

export default BoardNewsDtlContainer;
