import { httpRequest } from "@/util/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

/**
 * 비동기 액션 - 로그인
 */
export const login = createAsyncThunk(`auth/login`, async (arg) => {
  const info = { mail: arg.email, password: arg.password };
  const response = await httpRequest.post(`access/login`, info);

  return response;
});

/**
 * 비동기 액션 - 로그아웃(서버히스토리 기록용)
 */
export const logout = createAsyncThunk(`auth/logout`, async () => {
  const response = await httpRequest.post("access/logout", {});

  return response;
});

/**
 * 비동기 액선 - 새로고침 시 보유포인트 업데이트하기
 */
export const refreshPoint = createAsyncThunk("auth/refreshPoint", async () => {
  const {
    data: { point },
  } = await httpRequest.get(
    `user/${JSON.parse(sessionStorage.getItem("ipkk_user")).idx}`
  );

  return point;
});

/**
 * auth 슬라이스(리듀서+액션)
 */
export const authSlice = createSlice({
  name: `auth`,
  initialState: {
    isAuthenticated: sessionStorage.getItem(`ipkk_user`) ? true : false,
    user: JSON.parse(sessionStorage.getItem(`ipkk_user`)) || null,
    point: 0,
    isLoading: false,
    error: null,
  },

  reducers: {
    /* 동기 처리 */
    updatePoint: (state, action) => {
      state.point = action.payload;
    },
    clientLogout: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
      state.error = null;
    },
    clearError: (state, action) => {
      state.error = null;
    }
  },

  extraReducers: {
    /*  비동기 처리 */
    [login.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [login.fulfilled]: (state, action) => {
      /** 이메일인증 안된 사용자가 로그인하는 경우 */
      if (action.payload.status === 511) {
        window.location.href = `/join/regist?email=${action.meta.arg.email}`;
        return;
      }
      /** 이메일인증 된 사용자가 로그인하는 경우 */
      sessionStorage.setItem(`ipkk_user`, JSON.stringify(action.payload.data));
      state.isAuthenticated = true;
      state.user = action.payload.data;
      state.point = action.payload.data.point;
      state.isLoading = false;
      state.error = null;
    },
    [login.rejected]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
      state.error = "이메일 또는 비밀번호를 확인해주세요.";
    },

    [logout.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [logout.fulfilled]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
      state.error = null;
    },
    [logout.rejected]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
      state.error = null;
      console.error("로그아웃 중 에러가 발생하였습니다.");
    },
    [refreshPoint.fulfilled]: (state, action) => {
      state.point = action.payload;
    },
    [refreshPoint.rejected]: (state, action) => {
      return state;
    },
  },
});

export const { updatePoint, clientLogout, clearError } = authSlice.actions;

export default authSlice;
