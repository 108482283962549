import React from "react";
import { sendErrorMessage } from "@/util/common";

class ErrorBoundaryForLifecycle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    const errorObj = { error, errorInfo };
    sendErrorMessage("SCRIPT_ERROR", errorObj);
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryForLifecycle;
