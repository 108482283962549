import React from "react";

const KRGuide = () => {
  return (
    <div className="card pd-0">
      <div className="card__main pd-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"border-top-0"} scope="col" colSpan="1">
                번호종류
              </th>
              <th className={"border-top-0"} scope="col">
                번호형식
              </th>
              <th className={"border-top-0"} scope="col">
                예시
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="5">출원/공개/공고번호</td>
              <td>[연도 4자리] + [일련번호 7자리]</td>
              <td>2016-0002154</td>
            </tr>
            <tr>
              <td>KR + [연도 4자리] + [일련번호 7자리] + [문헌코드]</td>
              <td>KR20160002154A1</td>
            </tr>
            <tr>
              <td>[구분번호] + [연도 4자리] + [일련번호 7자리]</td>
              <td>1020160002154</td>
            </tr>
            <tr>
              <td>[구분번호] - [연도 4자리] - [일련번호 7자리]</td>
              <td>10-2016-0002154</td>
            </tr>
            <tr>
              <td>[구분번호] - [연도 4자리] - [일련번호 7자리] + [문헌코드]</td>
              <td>10-2016-0002154A</td>
            </tr>
            <tr>
              <td rowSpan="4">등록번호</td>
              <td>[일련번호 7자리]</td>
              <td>1623332</td>
            </tr>
            <tr>
              <td>KR + [일련번호 7자리]</td>
              <td>KR1623332</td>
            </tr>
            <tr>
              <td>[구분번호] + [일련번호 7자리] + 0000</td>
              <td>1016233320000</td>
            </tr>
            <tr>
              <td>[구분번호] - [일련번호 7자리]</td>
              <td>10-1623332</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KRGuide;
