export const COUNTRY_TYPE_INFO = {
  KR: {
    nameKo: "한국",
    countryType: "KR",
  },
  US: {
    nameKo: "미국",
    countryType: "US",
  },
  CN: {
    nameKo: "중국",
    countryType: "CN",
  },
  JP: {
    nameKo: "일본",
    countryType: "JP",
  },
  /* 추후 한중, 한일 추가 예정 */
};
