import React from "react";
import "./PriceModal.scss";

const PriceModal = ({ showPrice, togglePrice }) => {
  const showHideClassName = showPrice
    ? `price-modal display-block`
    : `price-modal display-none`;

  return (
    <div className={showHideClassName} onClick={togglePrice}>
      <section
        className="modal-contents"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-contents__header">
          <span>캐시충전</span>
          <div className="modal-contents__close-btn" onClick={togglePrice}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="modal-contents__main">
          <table>
            <thead>
              <tr>
                <th>충전캐시</th>
                <th className="text-danger">추가지급</th>
                <th>총 결제금액(VAT 포함)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>50,000캐시</td>
                <td className="text-danger">5,000캐시</td>
                <td>55,000원</td>
              </tr>
              <tr>
                <td>100,000캐시</td>
                <td className="text-danger">10,000캐시</td>
                <td>110,000원</td>
              </tr>
              <tr>
                <td>300,000캐시</td>
                <td className="text-danger">30,000캐시</td>
                <td>330,000원</td>
              </tr>
              <tr>
                <td>500,000캐시</td>
                <td className="text-danger">50,000캐시</td>
                <td>550,000원</td>
              </tr>
              <tr>
                <td>1,000,000캐시</td>
                <td className="text-danger">150,000캐시</td>
                <td>1,100,000원</td>
              </tr>
              <tr>
                <td>2,000,000캐시</td>
                <td className="text-danger">300,000캐시</td>
                <td>2,200,000원</td>
              </tr>
              <tr>
                <td>5,000,000캐시</td>
                <td className="text-danger">800,000캐시</td>
                <td>5,500,000원</td>
              </tr>
            </tbody>
          </table>
          <div className="card modal-contents__card mt-20 mb-20">
            <div className="card__header">
              <h4>충전방법</h4>
            </div>
            <div className="card__main">
              <ul>
                <li>
                  충전할 캐시에 해당하는 금액을 입금한다. (입금계좌 KB국민은행
                  084001-04-221802 / 입금자명 : (주)워트인텔리전스)
                </li>
                <li>
                  <b className="text-bold text-kk">cs@ipkingkong.com</b> 으로
                  입금일, 입금자명, 입금액, 연락처(핸드폰 번호)를 보낸다.
                </li>
                <li>
                  지출증빙으로 현금영수증 또는 세금계산서가 필요하신 분은 아래
                  내용을 <b className="text-bold text-kk">cs@ipkingkong.com</b>{" "}
                  으로 메일 보내주세요.
                  <ul>
                    <li>현금영수증 : 충전일, 성함, 핸드폰번호</li>
                    <li>
                      세금계산서 : 충전일, 사업자등록증 사본 첨부, 세금계산서
                      발행 이메일주소
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PriceModal;
