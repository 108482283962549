import React from "react";
import { Link } from "react-router-dom";
import ChargeInfoContainer from "@/commonModule/ChargeInfo/ChargeInfoContainer";
import { commafy, handleResetPaginationActive } from "@/util/common";
import ReactPaginate from "react-paginate";
import Calendar from "@/commonModule/Calendar/Calendar";

const PointHistory = ({
  pointType,
  fromDate,
  endDate,
  onChangeFieldValue,
  setFromDate,
  setEndDate,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="page-container">
      <div className="page__title">캐시 이용 내역</div>
      <small className="page__subtitle">
        캐시 충전 및 사용에 대한 내역을 조회할 수 있습니다.
      </small>
      <div className="divider"></div>
      <div className="d-flex justify-content-space-evenly w-two-three">
        <select
          className="select-box"
          id="pointType"
          name="pointType"
          value={pointType}
          onChange={onChangeFieldValue}
        >
          <option value="">전체</option>
          <option value="0">충전</option>
          <option value="1">사용</option>
        </select>
        <Calendar
          fromDate={fromDate}
          setFromDate={setFromDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <button
          className="btn btn-s"
          onClick={() => {
            onFetchPage(1);
            handleResetPaginationActive();
          }}
        >
          조회
        </button>
      </div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th>일시</th>
                <th>종류</th>
                <th>캐시</th>
                <th>잔액</th>
                <th>주문번호</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={5}>검색결과가 없습니다.</td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <tr key={`tbody-${i}`}>
                    <td>{post.regDttm}</td>
                    <td
                      className={`text-bold ${
                        post.typeName === "사용"
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {post.typeName}
                    </td>
                    <td
                      className={`text-bold ${
                        post.typeName === "사용"
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {commafy(post.point)}캐시
                    </td>
                    <td className="text-bold">
                      {commafy(post.totalPoint)}캐시
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: "/my/transHistory",
                          query: { orderNo: post.orderNo },
                        }}
                      >
                        {post.orderNo}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
      <ChargeInfoContainer className={"mt-20"} />
    </div>
  );
};

export default PointHistory;
