import { commafy } from "@/util/common";
import React from "react";
import "./Quit.scss";

const Quit = ({
  withdrawReason,
  quitInfo,
  name,
  point,
  errors,
  onChangeFieldValue,
  onSubmit,
}) => {
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="card w-full quit">
        <div className="card__header">회원탈퇴</div>
        <div className="card__notification">
          <div>
            {`현재 ${name}님은 충전금 
            ${commafy(point)} point를 보유하고 계십니다.`}
          </div>
          <div>* 회원 탈퇴시 캐시는 소멸되며 취소 및 복구가 불가합니다.</div>
        </div>
        <div className="card__main">
          <fieldset className="pt-10 pb-10 pl-20 pr-20">
            {" "}
            <div className="form-group radio">
              <label className="mb-5">탈퇴사유</label>
              <div className="h-full d-flex flex-direction-column align-items-flex-start line-height-6">
                {withdrawReason.map((v, i) => (
                  <label key={`withdrawReason-${i}`} className="c-radio mg-0">
                    <input
                      type="radio"
                      name="quitReason"
                      value={v.code}
                      onChange={onChangeFieldValue}
                    />
                    <span className="fa fa-circle" />
                    {v.codeName}
                  </label>
                ))}
              </div>
            </div>
            <p className="validation-message">{errors.quitReason}</p>
          </fieldset>
          <fieldset className="pt-10 pb-10 pl-20 pr-20">
            {" "}
            <div className="form-group mb-15">
              <label>비밀번호 확인</label>
              <div className="input-chaining">
                <input
                  className="form-control"
                  type="password"
                  name="quitPassword"
                  defaultValue={quitInfo.quitPassword}
                  onChange={onChangeFieldValue}
                />
              </div>
            </div>
            <p className="validation-message">{errors.quitPassword}</p>
          </fieldset>
        </div>

        <div className="card__footer align-items-end">
          <button className="btn btn-s bg-danger mb-10 mr-20">탈퇴하기</button>
        </div>
      </div>
    </form>
  );
};

export default Quit;
