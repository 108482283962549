import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({
  component: Component,
  layout: Layout,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return !isAuthenticated ? (
          <Layout {...props}>
            <Component {...props}> </Component>
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/" }} />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return { isAuthenticated: state.auth.isAuthenticated };
};

export default connect(mapStateToProps)(PublicRoute);
