import { autoWindowScroll } from "@/util/common";
import React, { Component } from "react";
import Footer from "./Footer";

class FooterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { showPrivacy: false, showPolicy: false };
  }

  render() {
    const { showPrivacy, showPolicy } = this.state;
    return (
      <Footer
        showPrivacy={showPrivacy}
        showPolicy={showPolicy}
        togglePrivacy={this.togglePrivacy}
        togglePolicy={this.togglePolicy}
        onClickIntroLink={this.handleClickIntroSectionLink}
        onClickPriceLink={this.handleClickPriceSectionLink}
      />
    );
  }

  /**
   * 개인정보처리방침 모달창 열고닫기
   */
  togglePrivacy = () => {
    const { showPrivacy } = this.state;
    if (!showPrivacy) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    this.setState({ showPrivacy: !this.state.showPrivacy });
  };

  /**
   * 이용약관 모달창 열고닫기
   */
  togglePolicy = () => {
    const { showPolicy } = this.state;
    if (!showPolicy) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    this.setState({ showPolicy: !this.state.showPolicy });
  };
  
  /**
   * 소개페이지로 이동하고 스크롤 위로 옮기기
   */
  handleClickIntroSectionLink = () => {
    const {
      location: { pathname },
      history,
    } = this.props;
    if (pathname === "/info/service") {
      autoWindowScroll("section-hero");
    } else {
      history.push("/info/service");
      
      const timer = setTimeout(() => {
        autoWindowScroll("section-hero");
        clearTimeout(timer);
      }, 100);
    }
  };
  
  /**
   * 소개페이지로 이동하고 번역 단가 섹션으로 이동
   */
  handleClickPriceSectionLink = () => {
    const {
      location: { pathname },
      history,
    } = this.props;
    if (pathname === "/info/service") {
      autoWindowScroll("section-cost");
    } else {
      history.push("/info/service");
      
      const timer = setTimeout(() => {
        autoWindowScroll("section-cost");
        clearTimeout(timer);
      }, 100);
    }
  };
}

export default FooterContainer;
