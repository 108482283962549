import React from "react";

const CNGuide = () => {
  return (
    <div className="card pd-0">
      <div className="card__main pd-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"border-top-0"} scope="col" colSpan="1">
                번호종류
              </th>
              <th className={"border-top-0"} scope="col">
                번호형식
              </th>
              <th className={"border-top-0"} scope="col">
                예시
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4">출원번호</td>
              <td>[연도 4자리] - [일련번호 8자리]</td>
              <td>2014-10666725</td>
            </tr>
            <tr>
              <td>[연도 4자리] + [일련번호 8자리]</td>
              <td>201410666725</td>
            </tr>
            <tr>
              <td>CN + [연도 4자리] + [일련번호 8자리]</td>
              <td>CN201410666725</td>
            </tr>
            <tr>
              <td>[연도 4자리] + [일련번호 8자리] + [.식별번호]</td>
              <td>201410666725.4</td>
            </tr>
            <tr>
              <td rowSpan="4">공개/등록번호</td>
              <td>[일련번호 7자리]</td>
              <td>2472715</td>
            </tr>
            <tr>
              <td>[일련번호 9자리]</td>
              <td>101969455</td>
            </tr>
            <tr>
              <td>CN + [일련번호 9자리]</td>
              <td>CN101969455</td>
            </tr>
            <tr>
              <td>CN + [일련번호 7자리]</td>
              <td>CN2472715</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CNGuide;
