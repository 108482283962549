import Socket from "@/util/socket";
import { createSlice } from "@reduxjs/toolkit";

export const socketSlice = createSlice({
  name: `socket`,
  initialState: {
    socketClient: null,
  },
  reducers: {
    /* 동기 처리 */
    createSocket: (state, action) => {
      state.socketClient = new Socket(action.payload);
    },
  },
  extraReducers: {
    /* 비동기 처리 */
  },
});

export const { createSocket } = socketSlice.actions;

export default socketSlice;
