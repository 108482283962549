import { commafy } from "@/util/common";
import React from "react";
import { Link } from "react-router-dom";
import "./WishList.scss";

const WishList = ({
  wishLists,
  chosen,
  onCheckbox,
  onCheckboxAll,
  onDeleteByIds,
  onOrder,
}) => {
  return (
    <div className="page-container">
      <div className="page__title">보관함</div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="wish-list-control">
          <div>
            <span>보관문헌</span>
          </div>
          <div>
            <button
              className={`btn btn-s ${
                Object.keys(chosen).length ? "" : "disabled"
              }`}
              onClick={onDeleteByIds}
            >
              삭제
            </button>
          </div>
        </div>
        <div className="table-container pt-0">
          {" "}
          <table>
            <thead>
              <tr>
                <th></th>
                <th>문헌명</th>
                <th>번역 범위</th>
                <th>번역 종류</th>
                <th>번역 유형</th>
                <th>캐릭터수</th>
                <th>금액</th>
              </tr>
            </thead>
            <tbody>
              {wishLists && wishLists.length === 0 && (
                <tr>
                  <td colSpan={7}>보관문헌이 없습니다.</td>
                </tr>
              )}
              {wishLists &&
                wishLists.length > 0 &&
                wishLists.map((post, i) => (
                  <tr key={`wishList-${i}`}>
                    <td>
                      {" "}
                      <div className="c-checkbox">
                        <label className="c-radio">
                          <input
                            type="radio"
                            name="wishResult"
                            className={"wishResult"}
                            defaultChecked={false}
                            onChange={() => onCheckbox(post, i)}
                          />
                          <span className="fa fa-check" />
                        </label>
                      </div>
                    </td>
                    <td>{post.documentName}</td>
                    <td>{post.transCoverageName}</td>
                    <td>{post.orgLangName + post.transLangName}</td>
                    <td>{post.transTypeName}</td>
                    <td>
                      {commafy(post.charCount)}
                      {post.orgLang === "EN" ? "단어" : "자"}
                    </td>
                    <td>{commafy(post.chargePoint)} 캐시</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end text-bold font-size-20 pd-20">
        <span>
          예상 결제금액{" "}
          {commafy(
            Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
          )}{" "}
          캐시
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <button
          onClick={onOrder}
          className={`btn wish-list__btn-order ${
            Object.keys(chosen).length ? "" : "disabled"
          }`}
        >
          주문하기
        </button>

        <Link to="/search/patentPublication" style={{ textDecoration: "none" }}>
          <button className="btn wish-list__btn-cancel">취소</button>
        </Link>
      </div>
    </div>
  );
};

export default WishList;
