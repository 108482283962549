import React, { Fragment } from "react";
import "./MainLayout.scss";
import NavbarContainer from "@/commonModule/Navbar/NavbarContainer";
import FooterContainer from "@/commonModule/Footer/FooterContainer";

const MainLayout = (props) => {
  return (
    <Fragment>
      <NavbarContainer {...props} />
      <div className="layout">
        {props.children}
      </div>
      <FooterContainer {...props} />
    </Fragment>
  );
};

export default MainLayout;
