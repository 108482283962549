import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const OrderSuccess = ({ history, location }) => {
  const orderNo = location.query && location.query.orderNo;
  let orderNoInicis = '';
  location.search && location.search.split('?')[1].split('&').forEach((data, idx) => {
    if( data.indexOf('orderNo') >= 0 ) {
      orderNoInicis = data.split('orderNo=')[1];
    }
  })

  useEffect(() => {
    if (!orderNo && !orderNoInicis) {
      history.push("/");
    }
  }, []);

  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-check" />
          </div>
          <div className="round-text-bold text-lg">
            AI 특허 번역 신청이 완료 되었습니다.
          </div>
        </div>
        <div className="result-page__contents-sub">
          <p className="text-md">주문번호: {orderNo || orderNoInicis} </p>
          <p className="">
            번역은 영업일기준 24시간 내에 완료되며, 완료시 이메일로 알려드립니다
          </p>
        </div>
        <div className="result-page__contents-direction">
          <Link to={"/my/transHistory"} className="btn btn-primary btn-xxl">
            My 번역
          </Link>
          <Link to={"/"} className="btn btn-primary btn-xxl">
            홈
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OrderSuccess;
