import { clientLogout } from "@/commonSlices/authSlice";
import store from "@/store";
import axios from "axios";
import moment from "moment";

/**
 * 서버로 에러메세지 발송(첫번째 에러만 발송)
 *
 * @param {string} type
 * @param {string} message
 */
let timer = null;
let errors = [];
export function sendErrorMessage(type, message) {
  clearTimeout(timer);
  errors.push({ type, message });
  timer = setTimeout(() => {
    console.error("에러 전송");
    console.error(errors[0].type, ": ", errors[0].message);
    // 추후 서버로 에러로그 전송 로직 추가. httpRequst.post(url, data, option)...
    errors = [];
    clearTimeout(timer);
  }, 300);
}

/**
 * 동적 스크립트 추가
 * @param src
 * @param async
 */
export function dynamicIncludeScript({ src, async }) {
  const $script = document.createElement("script");
  $script.language = "javascript";
  $script.type = "text/javascript";
  $script.src = src;
  $script.async = async;
  document.head.append($script);
}
/**
 * 표준시를 yyyy-MM-DD로 변경하는 함수.
 */
export function convertDateFormat(date) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  var day = date.getDate();
  day = day >= 10 ? day : "0" + day;
  return [year, month, day].join("");
}

/**
 * 목표 섹션 위치로 스크롤
 * @param sectionId
 */
export function autoWindowScroll(sectionId) {
  const sectionBCR = document.getElementById(sectionId).getBoundingClientRect();

  window.scroll({
    top: window.scrollY + sectionBCR.top - 79,
    left: 0,
    //behavior: "smooth",
  });
}

/**
 * 숫자 3자리마다 콤마 추가
 * ex) 123456789 => 123,456,789
 * @param number
 * @returns {string}
 */
export function commafy(number) {
  return number && number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * axios 인스턴스
 */
export const httpRequest = axios.create({
  baseURL: ip_config.baseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "K-Host": ip_config.kHost,
    "Api-Key": "bd39d5cf-4f7f-4cc7-a773-28b3100b",
  },
  validateStatus: (status) => {
    return status === 511 || status < 300;
  },
});

httpRequest.defaults.timeout = 900000;

httpRequest.interceptors.response.use(
  (response) => {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 데이터가 있는 작업 수행
    return response;
  },
  (error) => {
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 오류가 있는 작업 수행
    console.error("응답 에러 : ", error);

    if (
      store.getState().auth.user &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      store.dispatch(clientLogout());
    }
    return Promise.reject(error);
  }
);

/**
 * 번역단가
 */
export const TRANS_CODE_DATA = {
  KOEN: {
    typeName: "한영번역",
    orgLangName: "한글",
    unitCost: {
      /*    AA: basic, AB: special추가금액, AC: premium추가금액 */
      AA: 8.2,
      AB: 20,
      AC: 37.8,
    },
  },
  ENKO: {
    typeName: "영한번역",
    orgLangName: "영어",
    unitCost: {
      AA: 14,
      AB: 42,
      AC: 77,
    },
  },
  KOZH: {
    typeName: "한중번역",
    orgLangName: "한글",
    unitCost: {
      AA: 10,
      AB: 25,
      AC: 53,
    },
  },
  ZHKO: {
    typeName: "중한번역",
    orgLangName: "중국어",
    unitCost: {
      AA: 8,
      AB: 21,
      AC: 41,
    },
  },
  KOJA: {
    typeName: "한일번역",
    orgLangName: "한글",
    unitCost: {
      AA: 6,
      AB: 13,
      AC: 28,
    },
  },
  JAKO: {
    typeName: "일한번역",
    orgLangName: "일본어",
    unitCost: {
      AA: 5,
      AB: 11,
      AC: 27,
    },
  },
};

/**
 * 번역비용 계산하기
 * @param {*} charCount
 * @param {*} orgLang
 * @param {*} transLang
 * @param {*} option
 * @returns
 */
export const calculateCash = (charCount, orgLang, transLang, option) => {
  /*    AA: BASIC, AB: SPECIAL, AC: PREMIUM */
  switch (option) {
    case "AA":
      return Math.floor(
        charCount * TRANS_CODE_DATA[orgLang + transLang]["unitCost"]["AA"]
      );
    case "AB":
      return Math.floor(
        charCount * TRANS_CODE_DATA[orgLang + transLang]["unitCost"]["AA"] +
          charCount * TRANS_CODE_DATA[orgLang + transLang]["unitCost"]["AB"]
      );
    case "AC":
      return Math.floor(
        charCount * TRANS_CODE_DATA[orgLang + transLang]["unitCost"]["AA"] +
          charCount * TRANS_CODE_DATA[orgLang + transLang]["unitCost"]["AC"]
      );

    case "TRANSLATOR":
      return Math.floor(
        charCount * TRANS_CODE_DATA[orgLang + transLang]["unitCost"]["AB"]
      );
    case "PATENT_ATTORNEY":
      return Math.floor(
        charCount * TRANS_CODE_DATA[orgLang + transLang]["unitCost"]["AC"]
      );
    default:
      throw new Error(`unknown chosenType...`);
  }
};

/**
 * 국가코드를 언어코드로 바꾸기
 * @param {*} countryEnum
 * @returns
 */
export const convertIntoLangName = (countryEnum) => {
  switch (countryEnum) {
    case "KR":
    case "KO":
      return { kName: "한국어", name: "KO" };
    case "US":
    case "EN":
      return { kName: "영어", name: "EN" };
    case "CN":
    case "ZH":
      return { kName: "중국어", name: "ZH" };
    case "JP":
    case "JA":
      return { kName: "일본어", name: "JA" };
    default:
      throw new Error(`unknown countryEnum...`);
  }
};

/**
 * 페이지네이션 버튼 active 스킨 초기화하기
 */
export const handleResetPaginationActive = () => {
  const $container = document.querySelector(".pagination-container");
  const $paginationUl = $container.querySelector("ul");

  if ($paginationUl) {
    const $lists = $paginationUl.querySelectorAll("li");
    if ($lists) {
      $lists.forEach((list) => {
        list.classList.remove("active");
      });
      $lists[1].classList.add("active");
      $lists[1].querySelector("a").click();
    }
  }
};

/**
 * 다운로드 기간이 경과했는지 bool 리턴하는 함수
 */

export const checkIfExpired = (endDate) => {
  return (
    moment(endDate, "YYYY-MM-DD").diff(moment().format("YYYY[-]MM[-]DD")) < 0
  );
};

/**
 * 번역가 검수인지 아닌지 체크
 * @param transType
 * @returns {boolean}
 */
export function checkIsTranslatorInspection(transType) {
  let isTranslatorInspection = false;
  switch( transType ) {
    case 'AB':
    case 'AD': {
      isTranslatorInspection = true;
      break;
    }
  }
  return isTranslatorInspection;
}
