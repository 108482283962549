import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <section className="loader">
      <div className="loader__logo">
        <i className="fas fa-spinner"></i>
      </div>
      <div className="loader__title">Loading...</div>
      <div className="loader__text">로딩중입니다. 잠시만 기다려주세요.</div>
    </section>
  );
};

export default Loader;
