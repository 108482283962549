import React from "react";
import "./RadioBtn.scss";

const RadioBtn = ({ name, btnList, className, callback }) => {
  return (
    <div className={`btn-radio-container ${className}`}>
      {btnList &&
        btnList.map((btnInfo, idx) => {
          return (
            <div key={`${name}-${idx}`} className="btn-radio">
              <input
                type={"radio"}
                id={`${name}${idx}`}
                name={name}
                defaultChecked={btnInfo.defaultChecked}
                value={btnInfo.value}
                onClick={callback}
              />
              <label htmlFor={`${name}${idx}`}>{btnInfo.label}</label>
            </div>
          );
        })}
    </div>
  );
};

export default RadioBtn;
