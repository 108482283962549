import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.scss";

const Calendar = ({ fromDate, setFromDate, endDate, setEndDate }) => {
  const fromDateRef = useRef();
  const endDateRef = useRef();

  return (
    <div className="date-picker-container">
      <div className="date-picker-chaining">
        <DatePicker
          ref={fromDateRef}
          className="date-picker__control"
          selected={fromDate}
          onChange={(date) => {
            setFromDate(date);
          }}
          dateFormat={`yyyy-MM-dd`}
        />
        <div
          className="date-picker__icon"
          onClick={() => {
            fromDateRef.current.setFocus();
          }}
        >
          <i className="fas fa-calendar-alt"></i>
        </div>
      </div>

      <span className="date-picker-between"> ~ </span>
      <div className="date-picker-chaining">
        <DatePicker
          ref={endDateRef}
          className="date-picker__control"
          selected={endDate}
          onChange={(date) => {
            setEndDate(date);
          }}
          dateFormat={`yyyy-MM-dd`}
        />
        <div
          className="date-picker__icon"
          onClick={() => {
            endDateRef.current.setFocus();
          }}
        >
          <i className="fas fa-calendar-alt"></i>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
