import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import { commafy } from "@/util/common";
import LoginModal from "./fragments/LoginModal/LoginModal";

const Navbar = ({
  hamburgerClicked,
  onHamburgerClick,
  showModal,
  onShowModal,
  showDropdown,
  onMouseEnter,
  onMouseLeave,
  onClickPriceLink,
  onClickIntroLink,
  onClickMainPage,
  user,
  isAuthenticated,
  point,
  logout,
}) => {
  const authLink = (
    <Fragment>
      {" "}
      <li>
        <Link className="nav-menu__link" to="/my/pointHistory">
          <img src="/images/kcash_icon.png" alt="kcash" className="mr-5" />
          {point && commafy(point)}
        </Link>
      </li>
      <li>
        <Link to="#" className="nav-menu__link" onClick={onClickIntroLink}>
          IP KINGKONG 소개
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="/search/patentPublication">
          특허공보 번역
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="/translation/myDoc/uploader">
          내 문서 번역
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="/my/transHistory">
          MY 번역
        </Link>
      </li>
      <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Link className="nav-menu__link" to="#">
          <div>
            <i className="fas fa-user-circle" />
            <span>{user && user.name}</span>{" "}
            <i className="fas fa-caret-down ml-10" />
          </div>
        </Link>
        {showDropdown && (
          <Fragment>
            <ul className="dropdown-menu" onClick={onMouseLeave}>
              <li>
                <Link to="/my/userInfo" className="dropdown-menu__link">
                  <i className="fas fa-id-card" />
                  <span>내정보</span>
                </Link>
              </li>
              <li>
                <Link to="/my/wishList" className="dropdown-menu__link">
                  <i className="fas fa-archive" />
                  <span>보관함</span>
                </Link>
              </li>
              <li>
                <Link to="/my/pointHistory" className="dropdown-menu__link">
                  <i className="fas fa-file-invoice-dollar" />
                  <span>캐시이용내역</span>
                </Link>
              </li>
              <li>
                <Link to="#" className="dropdown-menu__link" onClick={logout}>
                  <i className="fas fa-sign-out-alt" />
                  <span> 로그아웃</span>
                </Link>
              </li>
            </ul>
          </Fragment>
        )}
      </li>
    </Fragment>
  );

  const guestLink = (
    <Fragment>
      <li>
        <Link to="#" className="nav-menu__link" onClick={onClickIntroLink}>
          IP KINGKONG 소개
        </Link>
      </li>
      <li>
        <Link to="#" className="nav-menu__link" onClick={onClickPriceLink}>
          이용요금
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="#" onClick={onShowModal}>
          로그인
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="/join/form">
          가입하기
        </Link>
      </li>
    </Fragment>
  );

  return (
    <div>
      <nav className="navbar">
        <div className="navbar__logo">
          <Link to="#" onClick={onClickMainPage}>
            <img src="/images/logo.png" alt="logo" />
          </Link>
        </div>
        <div className="navbar__hamburger-btn" onClick={onHamburgerClick}>
          <i className={hamburgerClicked ? `fas fa-times` : `fas fa-bars`}></i>
        </div>
        <ul
          className={hamburgerClicked ? `nav-menu active` : `nav-menu`}
          onClick={onHamburgerClick}
        >
          {isAuthenticated ? authLink : guestLink}
        </ul>
      </nav>
      <LoginModal show={showModal} onShowModal={onShowModal} />
    </div>
  );
};

export default Navbar;
