import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import RegistResult from "./RegistResult";

class RegistResultContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, isVerified: false };
  }

  componentDidMount() {
    this.checkMailAuth();
  }

  render() {
    const { isLoading, isVerified } = this.state;
    return <RegistResult isLoading={isLoading} isVerified={isVerified} />;
  }

  /**
   * 사용자이메일에서 가입인증링크 클릭하면 이 페이지 진입하게 되고, 토큰을 보내 가입메일 인증하기.
   */
  checkMailAuth = async () => {
    const {
      location: { search },
    } = this.props;
    const token = search.split("?token=")[1];

    try {
      await httpRequest.get(`auth?key=join&value=${token}`);
      this.setState({ isLoading: false, isVerified: true });
    } catch (err) {
      this.setState({ isLoading: false, isVerified: false });
    }
  };
}

export default RegistResultContainer;
