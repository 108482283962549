import React, { Fragment } from "react";
import "./MyDocument.scss";
import TransPreview from "@/commonModule/TransPreview/TransPreview";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

const MyDocument = ({
  currentStep,
  onMoveStep,
  file,
  onDrop,
  isSpecification,
  onRemove,
  chosen,
  onClickTransType,
  selOrgLang,
  selTransLang,
  isLoading,
  errors,
  onChangeFieldValue,
}) => {
  return (
    <div className="page-container">
      <div className={"page__header"}>
        <div className="page__title">내 문서 번역</div>
        <small className="page__subtitle">
          번역할 워드문서를 업로드해보세요.
        </small>
      </div>
      <div className={"page-contents"}>
        <div className="card">
          <div className="card__main my-document">
            <div className={"step-info-section"}>
              <div
                className={`step-info step-info-1 ${
                  currentStep === 1 ? "active" : ""
                }`}
                aria-invalid={false}
              >
                <div className={"step-title"}>1. 번역 정보 입력</div>
                <small>번역 언어 선택</small>
              </div>
              <div
                className={`step-info step-info-2 ${
                  currentStep === 2 ? "active" : ""
                }`}
                aria-invalid={false}
              >
                <div className={"step-title"}>2. 파일 업로드</div>
                <small>번역할 워드파일 업로드</small>
              </div>
              <div
                className={`step-info step-info-3 ${
                  currentStep === 3 ? "active" : ""
                }`}
                aria-invalid={false}
              >
                <div className={"step-title"}>3. 번역 비용 계산</div>
                <small>업로드된 파일의 번역 비용 계산</small>
              </div>
            </div>
            <div className={"step-contents-wrapper"}>
              <div className={"step-contents step-1"}>
                <div
                  className={
                    "d-flex w-full justify-content-space-around align-items-center"
                  }
                >
                  <label className={"subtitle mr-30"}>원문</label>
                  <label className={"subtitle"}>번역</label>
                </div>
                <div
                  className={
                    "d-flex w-full justify-content-space-around align-items-center"
                  }
                >
                  <div className={"w-one-three"}>
                    <select
                      className="form-control"
                      id="selOrgLang"
                      name="selOrgLang"
                      aria-invalid={false}
                      aria-errormessage={"selOrgLangErr"}
                      required
                      value={selOrgLang}
                      onChange={onChangeFieldValue}
                    >
                      <option value="">선택</option>
                      <option value="KO">한글</option>
                      <option value="EN">영어</option>
                      <option value="ZH">중국어</option>
                      <option value="JA">일본어</option>
                      {/* 일본어, 중국어 옵션추가예정 */}
                    </select>
                    <span id={"selOrgLangErr"} className={"error-message"}>
                      원문 언어를 선택해주세요.
                    </span>
                  </div>
                  <span className={"fa fa-arrow-circle-right direction-icon"} />
                  <div className={"w-one-three"}>
                    <select
                      className="form-control"
                      id="selTransLang"
                      name="selTransLang"
                      aria-invalid={false}
                      aria-errormessage={"selTransLangErr"}
                      required
                      value={selTransLang}
                      onChange={onChangeFieldValue}
                    >
                      <option value="">선택</option>
                      {selOrgLang === "" ? (
                        <Fragment>
                          <option id="optTransLang_KO" value="KO">
                            한글
                          </option>
                          <option id="optTransLang_EN" value="EN">
                            영어
                          </option>
                          <option value="ZH">중국어</option>
                          <option value="JA">일본어</option>
                        </Fragment>
                      ) : selOrgLang === "KO" ? (
                        <Fragment>
                          <option id="optTransLang_EN" value="EN">
                            영어
                          </option>
                          <option id="optTransLang_ZH" value="ZH">
                            중국어
                          </option>
                          <option id="optTransLang_JA" value="JA">
                            일본어
                          </option>
                          {/* 일본어, 중국어 옵션추가예정 */}
                        </Fragment>
                      ) : (
                        <option id="optTransLang_KO" value="KO">
                          한글
                        </option>
                      )}
                    </select>
                    <span id={"selTransLangErr"} className={"error-message"}>
                      번역 언어를 선택해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <div className={"step-contents step-2"}>
                <div className="alert alert-info" role="alert">
                  <h5 className="mb-0">
                    <em className="fa fa-info-circle" />
                    <strong>
                      {" "}
                      명세서 번역만 지원합니다. 그 외 특허문서는 서비스
                      예정입니다.
                    </strong>
                  </h5>
                </div>
                {isLoading ? (
                  <div className="drop-zone">
                    <div className="loading"></div>
                  </div>
                ) : !isSpecification ? (
                  <Dropzone onDrop={onDrop} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: "drop-zone",
                          id: "fileDropzone",
                        })}
                      >
                        <input {...getInputProps()} required />
                        <div>
                          <em
                            id="icoCloudUpload"
                            className="fas fa-cloud-upload-alt"
                          />
                        </div>
                        <div className={"upload-info-text"}>
                          <h4>
                            <span className="text-primary">워드</span>문서를
                            끌어다 놓거나 영역을 클릭해 주세요.
                          </h4>
                        </div>
                        <div>
                          <p>
                            <b>*.docx</b> 파일만 업로드해주세요.
                          </p>
                        </div>
                        <span className={"error-message mt-5"}>
                          업로드할 파일을 선택해주세요.
                        </span>
                        {errors && (
                          <p className="validation-message mt-5">
                            {errors.step2}
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>
                ) : (
                  <div className="drop-zone">
                    <div className="drop-zone__uploaded-container">
                      <div className="drop-zone__uploaded mb-10">
                        <span className="far fa-file-word"></span>
                        <div>
                          <strong>{(file.size / 1000).toFixed(1)}</strong> KB
                        </div>
                        <div>{file.name}</div>
                      </div>
                      <span>
                        <Link to="#" onClick={onRemove}>
                          Remove File
                        </Link>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={"step-contents step-3"}>
                <TransPreview
                  chosen={chosen}
                  savePct={"80"}
                  onClickTransType={onClickTransType}
                />
              </div>
            </div>
            <div className={"step-footer justify-content-end"}>
              <div className={"d-inline-flex"}>
                <button
                  id="prevStep"
                  className={`btn btn-s step-btn ${
                    currentStep === 1 ? "disabled" : "active"
                  }`}
                  type="button"
                  onClick={onMoveStep}
                >
                  이전
                </button>
                {currentStep !== 3 ? (
                  <button
                    id="nextStep"
                    className={`btn btn-s step-btn active ${
                      isLoading || (currentStep === 2 && errors.step2)
                        ? "disabled"
                        : ""
                    }`}
                    type="button"
                    onClick={onMoveStep}
                  >
                    다음
                  </button>
                ) : (
                  <button
                    id="order"
                    className={"btn btn-s step-btn active"}
                    type="button"
                    onClick={onMoveStep}
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname: "/payment/orderPayment",
                        query: {
                          chosen,
                          transReqType: "N",
                        },
                      }}
                    >
                      주문하기
                    </Link>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDocument;
