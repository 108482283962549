import React from "react";
import { Link } from "react-router-dom";
import "./RegistSuccess.scss";

const RegistSuccess = () => {
  return (
    <section className="regist-success">
      <div className="regist-success__logo">
        <em className="fas fa-check"></em>
      </div>
      <div className="regist-success__title">환영합니다!</div>
      <div className="regist-success__subtitle">
        IP KINGKONG 가입이 완료되었습니다.
      </div>
      <Link to="/login" className="btn btn-xl">
        <span>로그인</span>
      </Link>
    </section>
  );
};

export default RegistSuccess;
