export const PRIVACY_CONTENTS = {
    v4_0: `
        <p class="pt-5">
          ㈜워트인텔리전스 (이하 “회사”라 한다)는 개인정보 보호법 제30조에 따라 회사가 제공하는 IP 킹콩(IP KINGKONG) 서비스 (이하 “서비스”라 한다)를 이용하는 고객님의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리하기 위하여 다음과 같이 개인정보 처리 지침을 수립․공개합니다.
        </p>
        
        <p class="pt-3">
          회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 의거하여 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 사용자 권익 보호에 최선을 다하고 있습니다.
        </p>
        
        <p class="pt-3">
          본 개인정보처리방침은 회사가 제공하는 IP 킹콩(IP KINGKONG) 서비스 이용에 적용되며 다음과 같은 내용을 담고 있습니다.
        </p>
        
        <p class="pt-3">
          <b>1. 수집하는 개인정보의 항목 및 수집방법</b>
        </p>
        
        <p>
          <b>가. 수집하는 개인정보의 항목</b>
        </p>
        
        <p>
          회사는 사용자 가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 가입 당시 혹은 담당자 변경의 경우 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다.
        </p>
        <p>&lt; 회원 가입 및 서비스 제공 &gt;</p>
        
        <table>
          <colgroup>
            <col width="60px">
            <col width="250px">
            <col>
            <col width="120px">
          </colgroup>
          <tr>
            <th scope="col">분류</th>
            <th scope="col">목적</th>
            <th scope="col">항목</th>
            <th scope="col">보유 및 이용기간</th>
          </tr>
          <tr>
            <th scope="row" rowSpan="2">필수</th>
            <td>회원 가입 및 이용자 식별, 회원 관리</td>
            <td>이메일, 비밀번호, 종류(기업/개인), 이름, 휴대전화번호</td>
            <td rowSpan="2">회원탈퇴 시 파기</td>
          </tr>
          <tr>
            <td>사업자 세금계산서 발행</td>
            <td>사업자등록번호, 법인명, 대표자, 사업장 소재지, 사업의 종류</td>
          </tr>
          <tr>
            <th scope="row">선택</th>
            <td>서비스의 이벤트 프로모션 안내</td>
            <td>전화번호</td>
            <td>회원탈퇴 시 파기</td>
          </tr>
        </table>
        
        <p class="pt-10">&lt; 서비스 이용 과정 및 처리 과정으로 인한 수집 &gt;</p>
        
        <table>
          <colgroup>
            <col width="60px">
            <col width="250px">
            <col>
            <col width="120px">
          </colgroup>
          <tr>
            <th scope="col">분류</th>
            <th scope="col">목적</th>
            <th scope="col">항목</th>
            <th scope="col">보유 및 이용기간</th>
          </tr>
          <tr>
            <th scope="row">필수</th>
            <td>사용자의 추가정보를 사용자의 컴퓨터에서 찾아 접속을 통한 추가정보 입력 없이 사용자의 방문을 확인하여 서비스를 제공하기 위함</td>
            <td>OS종류, 접속 로그, 쿠키, 서비스 이용 기록, IP Address, 사내 네트워크 상황</td>
            <td>회원탈퇴 시 파기</td>
          </tr>
        </table>
        
        <p class="pt-10">
          <b>나. 개인정보 수집방법</b>
        </p>
        
        <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
        <table>
          <colgroup>
            <col width="150px">
            <col>
          </colgroup>
          <tr>
            <th scope="row">개인 정보 수집 방법</th>
            <td>홈페이지, 서면양식, 전화, 이메일</td>
          </tr>
        </table>
        
        <p class="pt-15">
          <b>2. 개인정보의 수집 및 이용목적 상세</b>
        </p>
        
        <p>
          <b>
            가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
          </b>
        </p>
        
        <p>
          특허정보 등의 컨텐츠 제공, 특정 맞춤 서비스 제공, 청구서 등 발송, 구매 및 요금 결제, 요금 추심
        </p>
        
        <p>
          <b>나. 사용자 관리</b>
        </p>
        
        <p>
          서비스 이용에 따른 사용자 확인, 사용자 식별, 서비스 이용약관 위반 사용자에 대한 이용제한 조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재, 가입의사 확인, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 탈퇴 의사의 확인
        </p>
        
        <p>
          <b>다. 신규 서비스 개발 및 마케팅•광고에의 활용</b>
        </p>
        
        <p>
          신규 서비스 개발 및 맞춤 서비스 제공, 회사의 연계 서비스들의 마케팅 활용 이용, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 이벤트 등 광고성 정보 전달, 포인트 제도 이용, 회원 등급 산정, 접속빈도 파악, 사용자의 서비스 이용에 대한 통계
        </p>
        
        <p class="pt-3">
          <b>3. 개인정보의 공유 및 제공</b>
        </p>
        
        <p>
          회사는 사용자의 개인정보를 "2. 개인정보의 수집 및 이용목적"에서 고지한 범위 내에서 사용하며, 사용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 사용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다. ‘IP킹콩 아이디로 로그인’ 기능을 IP 킹콩 서비스 외의 웹사이트에서 사용하시는 사용자의 경우, ‘IP킹콩 아이디로 로그인 운영정책’이 적용됨을 알려 드립니다.
        </p>
        
        <p>
          <b>가. 사용자가 사전에 동의한 경우</b>
        </p>
        
        <p>
          <b>
            나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
          </b>
        </p>
        
        <p>
          <b>다. 개인정보 제3자제공 현황</b>
        </p>
        
        <table>
          <colgroup>
            <col width="160px">
            <col width="200px">
            <col>
            <col width="120px">
          </colgroup>
          <tr>
            <th scope="col">제공받는 자</th>
            <th scope="col">제공 목적</th>
            <th scope="col">제공 정보</th>
            <th scope="col">보유 및 이용 기간</th>
          </tr>
          <tr>
            <th scope="row">키워트(keywert)</th>
            <td rowSpan="2">서비스의 이벤트 프로모션 알림 메일 수신</td>
            <td rowSpan="2">이름, 개인 이메일, 휴대전화번호, 회사명, 업무용 이메일</td>
            <td rowSpan="2">회원탈퇴 시 파기</td>
          </tr>
          <tr>
            <th scope="row">팻스푼(patspoon)</th>
          </tr>
        </table>
        
        <p class="pt-10">
          <b>4. 개인정보의 처리위탁</b>
        </p>
        
        <p>
          회사는 서비스 이용에 대한 결제처리 및 사용자 본인인증을 제외한 기타의 목적으로 사용자의 동의 없이 개인정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 사용자에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.
        </p>
        
        <p class="pt-3">
          <b>5. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</b>
        </p>
        <p>
          회사는 사용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 홈페이지를 운영하는데 이용되는 서버가 사용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 사용자의 컴퓨터 하드디스크에 저장됩니다. 사용자가 웹사이트에 접속을 하면 회사 컴퓨터는 사용자 컴퓨터 브라우저에 있는 쿠키 내용을 읽고, 사용자의 추가정보를 사용자의 컴퓨터에서 찾아 접속을 통한 추가정보 입력 없이 사용자의 방문을 확인하여 서비스를 제공할 수 있습니다.
        </p>
        <p>
          사용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
        </p>
        <p>
          쿠키 설정을 거부하는 방법으로는 사용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
        </p>
        <p>
          단, 사용자가 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
        </p>
        
        <p>&lt; 쿠키 설치 허용 여부를 지정하는 방법 &gt;</p>
        
        <table>
          <colgroup>
            <col width="80px">
            <col>
          </colgroup>
          <tr>
            <th scope="row">Edge</th>
            <td>메뉴 > 설정 > 개인 정보, 검색 및 서비스 또는 쿠키 및 사이트 권한</td>
          </tr>
          <tr>
            <th scope="row">Chrome</th>
            <td>메뉴 > 설정 > 개인 정보 보호 및 보안</td>
          </tr>
        </table>
        
        <p class="pt-10">
          <b>6. 개인정보 수집•이용 및 제공에 대한 동의철회(탈퇴)</b>
        </p>
        
        <p>
          사용자는 가입 등을 통해 개인정보의 수집•이용 및 제공에 대해 동의하신 내용을 언제든지 철회할 수 있습니다. 동의 철회는 개인정보관리책임자에게 전화, 이메일로 연락하시면 본인 확인 후 지체 없이 귀하의 개인정보를 파기하는 등 필요한 조치를 하겠습니다. 동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 귀하께 지체 없이 통지하도록 하겠습니다.
        </p>
        
        <p>
          단, 사용자의 서비스 이용과 관련하여 대금의 완제 또는 채권소멸이 되지 않은 경우 해소(처리완료)되는 시점까지 사용자의 개인정보 이용에 대한 동의철회(탈퇴)가 불가합니다.
        </p>
        
        <p class="pt-3">
          <b>7. 개인정보의 보유 및 이용기간</b>
        </p>
        
        <p>
          사용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
        </p>
        
        <p>
          <b>가. 회사 내부 방침에 의한 정보보유 사유</b>
        </p>
        <p>&lt; 부정이용기록(부정가입, 징계기록 등의 비정상적 서비스 이용기록) &gt;</p>
        <table>
          <colgroup>
            <col>
            <col width="100px">
          </colgroup>
          <tr>
            <th scope="col">보존 이유</th>
            <th scope="col">보존 기간</th>
          </tr>
          <tr>
            <td>부정 이용 방지</td>
            <td>1년</td>
          </tr>
        </table>
        
        <p class="pt-15">
          <b>나. 관련법령에 의한 정보보유 사유</b>
        </p>
        
        <p>
          상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 사용자 정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
        </p>
        
        <p>&lt; 계약 또는 청약철회 등에 관한 기록 &gt;</p>
        <table>
          <colgroup>
            <col>
            <col width="100px">
          </colgroup>
          <tr>
            <th scope="col">보존 이유</th>
            <th scope="col">보존 기간</th>
          </tr>
          <tr>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
        </table>
        
        <p class="pt-15">&lt; 웹사이트 방문기록 &gt;</p>
        <table>
          <colgroup>
            <col>
            <col width="100px">
          </colgroup>
          <tr>
            <th scope="col">보존 이유</th>
            <th scope="col">보존 기간</th>
          </tr>
          <tr>
            <td>통신비밀보호법</td>
            <td>3개월</td>
          </tr>
        </table>
        
        <p class="pt-15">
          <b>
            다. 회사는 사용자의 가입 및 서비스 이용정보(ID, 성명, 연락처, 주소, 이메일, 사업자번호, 회사명, 대표자명, 서비스 이용기록)를 서비스이용계약해지(탈퇴신청, 직권탈퇴 포함) 시에도 상기 명시한 정보보유 사유에 따라 일정기간 보유할 수 있으며, 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
          </b>
        </p>
        
        <p>
          <b>
            라. 회사가 보유기간을 미리 고지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 사용자의 동의를 받은 경우에는 약정한 기간 동안 보존합니다.
          </b>
        </p>
        
        <p class="pt-3">
          <b>8. 개인정보 파기절차 및 방법</b>
        </p>
        
        <p>
          사용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
        </p>
        <p>
          <b>가. 파기절차</b>
        </p>
        <ul class="mb-15">
          <li>
            - 사용자가 가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
          </li>
          <li>
            - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
          </li>
        </ul>
        
        <p>
          <b>나. 파기방법</b>
        </p>
        <ul class="mb-15">
          <li>
            - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
          </li>
          <li>
            - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
          </li>
        </ul>
        
        <p class="pt-3">
          <b>9. 사용자 및 법정대리인의 권리와 그 행사방법</b>
        </p>
        <p>&lt; 개인정보 열람, 정정 &gt;</p>
        <p>
          사용자는 당사가 처리하는 개인정보에 대하여 열람 및 정정을 신청 할 수 있습니다. 회사는 홈페이지를 통해서 언제든지 등록되어 있는 사용자의 개인정보를 열람하거나 정정을 요청 할 수 있도록 조치를 취하고 있습니다.
        </p>
        
        <p>&lt; 수집, 이용 제공에 대한 동의철회 &gt;</p>
        <p>
          사용자는 가입 등을 통하여 개인정보의 수집, 이용, 제공에 대해 동의하신 내용을 회사 홈페이지 또는 고객지원센터를 통해 언제든지 철회할 수 있습니다.
        </p>
        
        <p>&lt; 개인정보의 정확성, 최신성 확보 &gt;</p>
        <p>
          회사는 개인정보의 정확성 및 최신성 확보하기 위하여 다음과 같은 절차를 마련하고 있습니다. 개인정보를 정확하게 관리하기 위해 개인정보 입력 시 입력내용을 사전에 확인하는 절차를 거치게 됩니다. 오류정보를 발견한 경우 이를 정정하거나 삭제할 수 있도록 하는 절차를 두고 있습니다. 사용자가 개인정보에 대한 열람 및 정정요구 시 올바른 정보를 입력할 수 있는 절차나 방법을 마련하고 있습니다.
        </p>
        
        <p>&lt; 본인정보의 제3자 제공사실 통보 요구 &gt;</p>
        <p>
          사용자는 회사가 본인정보를 제 3자에게 제공한 경우 제공한 본인정보의 주요 내용 등을 알려주도록 회사에 요구할 수 있습니다.
        </p>
        <table>
          <colgroup>
            <col width="100px">
            <col>
          </colgroup>
          <tr>
            <th scope="col" colSpan="2">㈜워트인텔리전스</th>
          </tr>
          <tr>
            <th scope="row">전화번호</th>
            <td>02-521-0110</td>
          </tr>
          <tr>
            <th scope="row">이메일</th>
            <td><a href="mailto:cslab@keywert.com" target="_blank">cslab@keywert.com</a></td>
          </tr>
          <tr>
            <th scope="row">인터넷</th>
            <td><a href="http://www.keywert.com" target="_blank">www.keywert.com</a></td>
          </tr>
          <tr>
            <th scope="row">서면</th>
            <td>서울시 서초구 강남대로 507, 6층</td>
          </tr>
        </table>
        
        <p class="pt-10">
          <b>10. 개인정보의 기술적/관리적 보호 대책</b>
        </p>
        
        <p>
          회사는 사용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
        </p>
        
        <p>
          <b>가. 비밀번호 암호화</b>
        </p>
        
        <p>
          서비스 사용자 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리됩니다.
        </p>
        
        <p>
          <b>나. 해킹 등에 대비한 대책</b>
        </p>
        
        <p>
          회사는 해킹이나 컴퓨터 바이러스 등에 의해 사용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 사용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 기술적 장치를 갖추려 노력하고 있습니다.
        </p>
        
        <p>
          <b>다. 처리 직원의 최소화 및 교육</b>
        </p>
        
        <p>
          회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 서비스 개인정보처리방침의 준수를 항상 강조하고 있습니다.
        </p>
        
        <p>
          <b>라. 개인정보보호전담기구의 운영</b>
        </p>
        
        <p>
          회사는 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 사용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
        </p>
        
        <p>
          <b>마. 개인정보관리책임자 및 담당자의 연락처</b>
        </p>
        
        <p>
          귀하께서는 회사의 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 사용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
        </p>
        
        <p>&lt; 개인정보관리책임자 &gt;</p>
        
        <table>
          <colgroup>
            <col width="80px">
            <col width="80px">
            <col>
            <col>
          </colgroup>
          <tr>
            <th scope="col">소속</th>
            <th scope="col">이름</th>
            <th scope="col">연락처</th>
            <th scope="col">이메일</th>
          </tr>
          <tr>
            <td>CRM팀</td>
            <td>최민형</td>
            <td>070-4172-4890</td>
            <td>crm@wert.co.kr</td>
          </tr>
        </table>
        
        <p class="pt-15">
          기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        </p>
        <ul class="mb-15">
          <li>- 개인정보침해신고센터 (www.118.or.kr / 국번 없이 118)</li>
          <li>- 대검찰청 사이버범죄수사단 (www.spo.go.kr / 02-3480-3571)</li>
          <li>
            - 연락처: 070-8299-8420- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 국번 없이 182)
          </li>
        </ul>
        
        <p class="pt-3">
          <b>11. 기타</b>
        </p>
        
        <p>
          서비스에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "IP 킹콩(IP KINGKONG) 개인정보처리방침"이 적용되지 않음을 알려 드립니다.
        </p>
        
        <p class="pt-3">
          <b>12. 고지의 의무</b>
        </p>
        
        <p>
          현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 사용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
        </p>
        <ul class="mb-15">
          <li>- 공고일자: 2024년 2월 21일</li>
          <li>- 시행일자: 2024년 2월 28일</li>
        </ul>
    `,
    v3_0: `
        <p class="pt-5">
            ㈜워트인텔리전스 (이하 ‘워트’라 한다)는 개인정보 보호법 제30조에
            따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고
            원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
            처리지침을 수립․공개합니다.
          </p>

          <p class="pt-3">
            ㈜워트인텔리전스("회사" 또는 "워트"라 함)는 정보통신망 이용촉진 및
            정보보호 등에 관한 법률에 의거하여 정보통신서비스제공자가 준수하여야
            할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한
            개인정보취급방침을 정하여 사용자 권익 보호에 최선을 다하고 있습니다.
            본 개인정보취급방침은 회사가 제공하는 IP킹콩 서비스 이용에 적용되며
            다음과 같은 내용을 담고 있습니다.
          </p>

          <p class="pt-3">
            <b>1. 수집하는 개인정보의 항목 및 수집방법</b>
          </p>

          <p>
            <b>가. 수집하는 개인정보의 항목</b>
          </p>

          <p>
            회사는 사용자 가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초
            가입 당시 혹은 담당자 변경의 경우 아래와 같은 최소한의 개인정보를
            필수항목으로 수집하고 있습니다.
          </p>
          <p>&lt; 가입 &gt;</p>
          <ul class="mb-15">
            <li>
              - 필수 : 성명(성명, 회사명 중 택일), 연락처(휴대전화번호), 이메일
              주소
            </li>
            <li>- 선택: 유선전화번호</li>
          </ul>
          <p>&lt; 서비스 이용 과정 및 처리 과정으로 인한 수집 &gt;</p>
          <ul class="mb-15">
            <li>
              - OS종류, 접속 로그, 쿠키, 서비스 이용 기록, IP Address, 사내
              네트워크 상황
            </li>
          </ul>

          <p>
            <b>나. 개인정보 수집방법</b>
          </p>

          <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
          <ul class="mb-15">
            <li>- 홈페이지, 서면양식, 전화, 이메일</li>
          </ul>

          <p class="pt-3">
            <b>2. 개인정보의 수집 및 이용목적</b>
          </p>

          <p>
            <b>
              가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
            </b>
          </p>

          <p>
            특허번역서비스 제공, 청구서 등 발송, 구매 및 요금 결제, 요금 추심
          </p>

          <p>
            <b>나. 사용자 관리</b>
          </p>

          <p>
            서비스 이용에 따른 사용자 확인, 사용자 식별, IP킹콩 이용약관 위반
            사용자에 대한 이용제한 조치, 서비스의 원활한 운영에 지장을 미치는
            행위 및 서비스 부정이용 행위 제재, 가입의사 확인, 가입 및 가입횟수
            제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리
            등 민원처리, 고지사항 전달, 탈퇴 의사의 확인
          </p>

          <p>
            <b>다. 신규 서비스 개발 및 마케팅•광고에의 활용</b>
          </p>

          <p>
            신규 서비스 개발 및 맞춤 서비스 제공, IP킹콩의 연계 서비스들의
            마케팅 활용 이용, 통계학적 특성에 따른 서비스 제공 및 광고 게재,
            이벤트 등 광고성 정보 전달, 마일리지 제도 이용, 회원 등급 산정,
            접속빈도 파악, 사용자의 서비스 이용에 대한 통계
          </p>

          <p class="pt-3">
            <b>3. 개인정보의 공유 및 제공</b>
          </p>

          <p>
            회사는 사용자의 개인정보를 "2. 개인정보의 수집 및 이용목적"에서
            고지한 범위 내에서 사용하며, 사용자의 사전 동의 없이는 동 범위를
            초과하여 이용하거나 원칙적으로 사용자의 개인정보를 외부에 공개하지
            않습니다. 다만, 아래의 경우에는 예외로 합니다. ‘IP킹콩 아이디로
            로그인’ 기능을 IP킹콩 외의 웹사이트에서 사용하시는 사용자의 경우,
            ‘IP킹콩 아이디로 로그인 운영정책’이 적용됨을 알려 드립니다.
          </p>

          <p>
            <b>가. 사용자가 사전에 동의 한 경우</b>
          </p>

          <p>
            <b>
              나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
              방법에 따라 수사기관의 요구가 있는 경우
            </b>
          </p>

          <p class="pt-3">
            <b>4. 개인정보의 취급위탁</b>
          </p>

          <p>
            회사는 서비스 이용에 대한 결제처리 및 사용자 본인인증을 제외한
            기타의 목적으로 사용자의 동의 없이 개인정보를 외부 업체에 위탁하지
            않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무
            내용에 대해 사용자에게 통지하고 필요한 경우 사전 동의를 받도록
            하겠습니다.
          </p>

          <p class="pt-3">
            <b>5. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</b>
          </p>
          <p>
            회사는 사용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
            운용합니다. 쿠키란 홈페이지를 운영하는데 이용되는 서버가 사용자의
            브라우저에 보내는 아주 작은 텍스트 파일로서 사용자의 컴퓨터
            하드디스크에 저장됩니다. 사용자가 웹사이트에 접속을 하면 회사
            컴퓨터는 사용자 컴퓨터 브라우저에 있는 쿠키 내용을 읽고, 사용자의
            추가정보를 사용자의 컴퓨터에서 찾아 접속을 통한 추가정보 입력 없이
            사용자의 방문을 알 수 있고 서비스를 제공할 수 있습니다.
          </p>
          <p>
            사용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 웹
            브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
            저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
            있습니다.
          </p>
          <p>
            쿠키 설정을 거부하는 방법으로는 사용자가 사용하는 웹 브라우저의
            옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
            확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
          </p>

          <p>
            &lt; 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)
            &gt;
          </p>
          <ul class="mb-15">
            <li>① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</li>
            <li>② [개인정보 탭]을 클릭합니다.</li>
            <li>
              ③ [개인정보취급 수준]을 설정하시면 됩니다. 단, 사용자가 쿠키
              설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
            </li>
          </ul>

          <p class="pt-3">
            <b>6. 개인정보 수집•이용 및 제공에 대한 동의철회(탈퇴)</b>
          </p>

          <p>
            사용자는 가입 등을 통해 개인정보의 수집•이용 및 제공에 대해 동의하신
            내용을 언제든지 철회할 수 있습니다. 동의 철회는
            개인정보관리책임자에게 전화, 이메일로 연락하시면 본인 확인 후 지체
            없이 귀하의 개인정보를 파기하는 등 필요한 조치를 하겠습니다. 동의
            철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을
            귀하께 지체 없이 통지하도록 하겠습니다.
          </p>

          <p>
            단, 사용자의 서비스 이용과 관련하여 대금의 완제 또는 채권소멸이 되지
            않은 경우 해소(처리완료)되는 시점까지 사용자의 개인정보 이용에 대한
            동의철회(탈퇴)가 불가합니다.
          </p>

          <p class="pt-3">
            <b>7. 개인정보의 보유 및 이용기간</b>
          </p>

          <p>
            사용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
            달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
            이유로 명시한 기간 동안 보존합니다.
          </p>

          <p>
            <b>가. 회사 내부 방침에 의한 정보보유 사유</b>
          </p>
          <p>
            &lt; 부정이용기록(부정가입, 징계기록 등의 비정상적 서비스 이용기록)
            &gt;
          </p>
          <ul class="mb-15">
            <li>- 보존 이유: 부정 이용 방지</li>
            <li>- 보존 기간: 1년</li>
          </ul>

          <p>
            <b>나. 관련법령에 의한 정보보유 사유</b>
          </p>

          <p>
            상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
            규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한
            일정한 기간 동안 사용자 정보를 보관합니다. 이 경우 회사는 보관하는
            정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
          </p>

          <p>&lt; 계약 또는 청약철회 등에 관한 기록 &gt;</p>
          <ul class="mb-15">
            <li>- 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률</li>
            <li>- 보존 기간: 5년</li>
          </ul>

          <p>&lt; 웹사이트 방문기록 &gt;</p>
          <ul class="mb-15">
            <li>- 보존 이유: 통신비밀보호법</li>
            <li>- 보존 기간: 3개월</li>
          </ul>

          <p>
            <b>
              다. 회사는 사용자의 가입 및 서비스 이용정보(ID, 성명, 연락처,
              주소, 이메일, 사업자번호, 회사명, 대표자명, 서비스 이용기록)를
              서비스이용계약해지(탈퇴신청, 직권탈퇴 포함) 시에도 상기 명시한
              정보보유 사유에 따라 일정기간 보유할 수 있으며, 이용목적이 달성된
              후에는 해당 정보를 지체 없이 파기합니다.
            </b>
          </p>

          <p>
            <b>
              라. 회사가 보유기간을 미리 고지하고 그 보유기간이 경과하지 아니한
              경우와 개별적으로 사용자의 동의를 받은 경우에는 약정한 기간 동안
              보존합니다.
            </b>
          </p>

          <p class="pt-3">
            <b>8. 개인정보 파기절차 및 방법</b>
          </p>

          <p>
            사용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
            달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은
            다음과 같습니다.
          </p>
          <p>
            <b>가. 파기절차</b>
          </p>
          <ul class="mb-15">
            <li>
              - 사용자가 가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의
              DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련
              법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간
              저장된 후 파기됩니다.
            </li>
            <li>
              - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른
              목적으로 이용되지 않습니다.
            </li>
          </ul>

          <p>
            <b>나. 파기방법</b>
          </p>
          <ul class="mb-15">
            <li>
              - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기합니다.
            </li>
            <li>
              - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
              기술적 방법을 사용하여 삭제합니다.
            </li>
          </ul>

          <p class="pt-3">
            <b>9. 사용자 및 법정대리인의 권리와 그 행사방법</b>
          </p>
          <p>&lt; 개인정보 열람, 정정 &gt;</p>
          <p>
            사용자는 당사가 처리하는 개인정보에 대하여 열람 및 정정을 신청 할 수
            있습니다. 회사는 홈페이지를 통해서 언제든지 등록되어 있는 사용자의
            개인정보를 열람하거나 정정을 요청 할 수 있도록 조치를 취하고
            있습니다.
          </p>

          <p>&lt; 수집, 이용 제공에 대한 동의철회 &gt;</p>
          <p>
            사용자는 가입 등을 통하여 개인정보의 수집, 이용, 제공에 대해
            동의하신 내용을 회사 홈페이지 또는 고객지원센터를 통해 언제든지
            철회할 수 있습니다.
          </p>

          <p>&lt; 개인정보의 정확성, 최신성 확보 &gt;</p>
          <p>
            회사는 개인정보의 정확성 및 최신성 확보하기 위하여 다음과 같은
            절차를 마련하고 있습니다. 개인정보를 정확하게 관리하기 위해 개인정보
            입력 시 입력내용을 사전에 확인하는 절차를 거치게 됩니다. 오류정보를
            발견한 경우 이를 정정하거나 삭제할 수 있도록 하는 절차를 두고
            있습니다. 사용자가 개인정보에 대한 열람 및 정정요구 시 올바른 정보를
            입력할 수 있는 절차나 방법을 마련하고 있습니다.
          </p>

          <p>
            &lt; 본인정보의 제3자 제공사실 통보 요구 &gt;사용자는 회사가
            본인정보를 제 3자에게 제공한 경우 제공한 본인정보의 주요 내용 등을
            알려주도록 회사에 요구할 수 있습니다.
          </p>
          <p>&lt; 신청방법 &gt;</p>
          <p>㈜워트인텔리전스</p>
          <p>전화번호: 02-521-0110</p>
          <p>이메일: cs@ipkingkong.com</p>
          <p>인터넷: www.ipkingkong.com</p>
          <p>서면: 서울시 서초구 강남대로 507, 6층</p>

          <p class="pt-3">
            <b>10. 개인정보의 기술적/관리적 보호 대책</b>
          </p>

          <p>
            회사는 사용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난,
            누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
            기술적/관리적 대책을 강구하고 있습니다.
          </p>

          <p>
            <b>가. 비밀번호 암호화</b>
          </p>

          <p>
            IP킹콩 사용자 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리됩니다.
          </p>

          <p>
            <b>나. 해킹 등에 대비한 대책</b>
          </p>

          <p>
            회사는 해킹이나 컴퓨터 바이러스 등에 의해 사용자의 개인정보가
            유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
            개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신
            백신프로그램을 이용하여 사용자들의 개인정보나 자료가 누출되거나
            손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여
            네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
            그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
            있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 기술적
            장치를 갖추려 노력하고 있습니다.
          </p>

          <p>
            <b>다. 취급 직원의 최소화 및 교육</b>
          </p>

          <p>
            회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한
            별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한
            수시 교육을 통하여 IP킹콩 개인정보취급방침의 준수를 항상 강조하고
            있습니다.
          </p>

          <p>
            <b>라. 개인정보보호전담기구의 운영</b>
          </p>

          <p>
            회사는 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여
            문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고
            있습니다. 단, 사용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호
            등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지
            않습니다.
          </p>

          <p>
            <b>마. 개인정보관리책임자 및 담당자의 연락처</b>
          </p>

          <p>
            귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호
            관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
            회사는 사용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴
            것입니다.
          </p>

          <p>&lt; 개인정보관리책임자 &gt;</p>
          <ul class="mb-15">
            <li>- 소속: CRM팀</li>
            <li>- 이름: 최민형</li>
            <li>- 연락처: 070-4172-4890</li>
            <li>- 이메일: crm@wert.co.kr</li>
          </ul>

          <p>
            &lt; 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
            기관에 문의하시기 바랍니다. &gt;
          </p>
          <ul class="mb-15">
            <li>- 개인정보침해신고센터 (www.118.or.kr / 국번 없이 118)</li>
            <li>- 대검찰청 사이버범죄수사단 (www.spo.go.kr / 02-3480-3571)</li>
            <li>
              - 연락처: 070-8299-8420- 경찰청 사이버테러대응센터 (www.ctrc.go.kr
              / 국번 없이 182)
            </li>
          </ul>

          <p class="pt-3">
            <b>11. 기타</b>
          </p>

          <p>
            IP킹콩에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에
            대해서는 본 "IP킹콩 개인정보취급방침"이 적용되지 않음을 알려
            드립니다.
          </p>

          <p class="pt-3">
            <b>12. 고지의 의무</b>
          </p>

          <p>
            현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
            7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 다만,
            개인정보의 수집 및 활용, 제3자 제공 등과 같이 사용자 권리의 중요한
            변경이 있을 경우에는 최소 30일 전에 고지합니다.
          </p>
          <ul class="mb-15">
            <li>- 공고일자: 2018년 10월 11일</li>
            <li>- 시행일자: 2018년 10월 28일</li>
          </ul>
    `
}
