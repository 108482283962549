import React, { Component } from "react";
import ChargeInfo from "@/commonModule/ChargeInfo/ChargeInfo";
import PriceModal from "@/commonModule/ChargeInfo/fragments/PriceModal/PriceModal";

export default class ChargeInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPrice: false,
    };
  }

  render() {
    const { className } = this.props;
    const { showPrice } = this.state;
    return (
      <div className={className}>
        <ChargeInfo togglePrice={this.togglePrice} />
        <PriceModal showPrice={showPrice} togglePrice={this.togglePrice} />
      </div>
    );
  }
  /**
   * 가격정보 모달창 열고닫기
   */
  togglePrice = () => {
    const { showPrice } = this.state;
    if (!showPrice) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    this.setState({ ...this.state, showPrice: !this.state.showPrice });
  };
}
