import React from "react";

const JPGuide = () => {
  return (
    <div className="card pd-0">
      <div className="card__main pd-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"border-top-0"} scope="col" colSpan="1">
                번호종류
              </th>
              <th className={"border-top-0"} scope="col">
                번호형식
              </th>
              <th className={"border-top-0"} scope="col">
                예시
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="8">출원/공개/공고번호(JP)</td>
              <td colSpan="2" className="text-bold">
                연도
              </td>
            </tr>
            <tr>
              <td>[연도 4자리] - [일련번호 6자리]</td>
              <td>2015-193855</td>
            </tr>
            <tr>
              <td>JP + [연도 4자리] + [일련번호 6자리] + [문헌코드]</td>
              <td>JP2015193855A</td>
            </tr>
            <tr>
              <td>[연도 4자리] + [일련번호 6자리]</td>
              <td>2015193855</td>
            </tr>
            <tr>
              <td colSpan="2" className="text-bold">
                연호
              </td>
            </tr>
            <tr>
              <td>[연호 2자리] - [일련번호 6자리]</td>
              <td>02-418732</td>
            </tr>
            <tr>
              <td>JP + [연호 2자리] + [일련번호 6자리] + [문헌코드]</td>
              <td>JP02418732A</td>
            </tr>
            <tr>
              <td>[연호 2자리] + [일련번호 6자리]</td>
              <td>02418732</td>
            </tr>
            <tr>
              <td rowSpan="7">재공표번호</td>
              <td>[연도 4자리]/[일련번호 6자리]</td>
              <td>2014/073020</td>
            </tr>
            <tr>
              <td>WO[연도 4자리]/[일련번호 6자리]</td>
              <td>WO2014/073020</td>
            </tr>
            <tr>
              <td>WO[연도 4자리]-[일련번호 6자리]</td>
              <td>WO2014-073020</td>
            </tr>
            <tr>
              <td>JP WO[연도 4자리]/[일련번호 6자리]</td>
              <td>JP WO2014/073020</td>
            </tr>
            <tr>
              <td>JPWO[연도 4자리]+[일련번호 6자리]-[문헌코드]</td>
              <td>JPWO2014073020A1</td>
            </tr>
            <tr>
              <td>JPWO[연도 4자리]/[일련번호 6자리]</td>
              <td>JPWO2014/073020</td>
            </tr>
            <tr>
              <td>WO[연도 2자리]/[일련번호 6자리]-[문헌코드]</td>
              <td>WO12/105693A1</td>
            </tr>
            <tr>
              <td rowSpan="3">등록번호</td>
              <td>[일련번호 7자리]</td>
              <td>4242250</td>
            </tr>
            <tr>
              <td>[일련번호 8자리]</td>
              <td>04242250</td>
            </tr>
            <tr>
              <td>JP + [일련번호 7자리] + [문헌코드]</td>
              <td>JP4242250B2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JPGuide;
