import React from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Privacy from "@/commonModule/Privacy/Privacy";
import Policy from "@/commonModule/Policy/Policy";

const Join = ({
  joinInfo,
  onChangeFieldValue,
  onDupCheck,
  onSubmit,
  onRadio,
  onCheckbox,
  onCheckboxAll,
  errors,
  showPrivacy,
  showPolicy,
  togglePrivacy,
  togglePolicy,
  onRecaptcha,
}) => {
  return (
    <div className="page-container">
      <div className={"page__header"}>
        <div className="page__title">무료회원가입</div>
        <small className="page__subtitle">
          무료로 IP KINGKONG에 가입하시고 인공신경망을 이용한 고품질 특허번역을
          경험해보세요.
        </small>
      </div>
      <form className="d-flex" onSubmit={onSubmit}>
        <div className="card w-half">
          <div className="card__header">회원 정보 입력</div>
          <div className="card__main">
            <fieldset className="mb-20">
              <div className="form-group">
                <label>이메일 *</label>
                <div className="input-chaining">
                  <input
                    className={"form-control"}
                    type="text"
                    name="email"
                    placeholder="이메일을 입력해주세요."
                    value={joinInfo.email}
                    onChange={onChangeFieldValue}
                    required
                  />
                  <div className="append" onClick={onDupCheck}>
                    <button
                      id="search"
                      className={"btn btn-s h-full border-0 bg-w"}
                      type="button"
                    >
                      중복확인
                    </button>
                  </div>
                </div>
                <p className="validation-message">{errors.email}</p>
                {joinInfo.dupCheck && (
                  <p className="validation-message--success">Good!</p>
                )}
              </div>
              <div className="form-group">
                <label>
                  비밀번호 *
                  <small>(8 ~ 32 자의 영문, 숫자, 특수문자 조합)</small>
                </label>
                <div className="input-chaining">
                  <input
                    className={"form-control"}
                    type="password"
                    name="password"
                    placeholder="비밀번호를 입력해주세요."
                    value={joinInfo.password}
                    onChange={onChangeFieldValue}
                  />
                  <div className="append">
                    <span className="fas fa-lock dummy-btn bg-w h-full" />
                  </div>
                </div>
                <p className="validation-message">{errors.password}</p>
              </div>
              <div className="form-group">
                <label>비밀번호 확인 *</label>
                <div className="input-chaining">
                  <input
                    className={"form-control"}
                    type="password"
                    name="retypePassword"
                    placeholder="확인을 위해 다시 한번 비밀번호를 입력해주세요."
                    value={joinInfo.retypePassword}
                    onChange={onChangeFieldValue}
                  />
                  <div className="append">
                    <span className="fas fa-lock dummy-btn bg-w h-full" />
                  </div>
                </div>
                <p className="validation-message">{errors.retypePassword}</p>
              </div>
            </fieldset>
            <fieldset>
              <div className="form-group radio">
                <label>종류</label>

                <div className="mt-5 mb-10 ">
                  <label className="c-radio">
                    <input
                      type="radio"
                      name="memberType"
                      value="2"
                      checked={joinInfo.memberType === `2`}
                      onChange={onRadio}
                    />{" "}
                    <span className="fa fa-circle" />
                    기업
                  </label>
                  <label className="c-radio ml-5">
                    <input
                      type="radio"
                      name="memberType"
                      value="1"
                      checked={joinInfo.memberType === `1`}
                      onChange={onRadio}
                    />
                    <span className="fa fa-circle" />
                    개인
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label>이름 *</label>
                <input
                  className={"form-control"}
                  type="text"
                  name="name"
                  placeholder={
                    joinInfo.memberType === "2"
                      ? "기업명을 입력해주세요."
                      : "이름을 입력해주세요."
                  }
                  value={joinInfo.name}
                  onChange={onChangeFieldValue}
                />
                <p className="validation-message">{errors.name}</p>
              </div>
              <div className="form-group">
                <label>휴대전화번호 *</label>
                <input
                  className={"form-control"}
                  type="text"
                  name="hphone"
                  placeholder="휴대전화번호 ( - 없이 숫자만 입력)"
                  maxLength="12"
                  value={joinInfo.hphone}
                  onChange={onChangeFieldValue}
                />
                <p className="validation-message">{errors.hphone}</p>
              </div>
              <div className="form-group">
                <label>전화번호</label>
                <input
                  className={"form-control"}
                  type="text"
                  name="phoneNo"
                  placeholder="유선번호 ( - 없이 숫자만 입력)"
                  maxLength="12"
                  value={joinInfo.phoneNo}
                  onChange={onChangeFieldValue}
                />
                <p className="validation-message">{errors.phoneNo}</p>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="card w-half mt-0 ml-30">
          <div className="card__header">이용약관 내용 동의</div>
          <div className="card__main">
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  onChange={onCheckboxAll}
                  checked={
                    joinInfo.agree14over &&
                    joinInfo.agreeServiceUse &&
                    joinInfo.agreePersonalInfo &&
                    joinInfo.agreeAd
                  }
                />
                <span className="fa fa-check" />
                <span>전체동의</span>
              </label>
            </div>

            <div className="divider"></div>
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agree14over"
                  checked={joinInfo.agree14over}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>만 14세 이상입니다(필수)</span>
              </label>
              <p className="validation-message">{errors.agree14over}</p>
            </div>
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agreeServiceUse"
                  checked={joinInfo.agreeServiceUse}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>서비스 이용약관 동의(필수)</span>
              </label>

              <small onClick={togglePolicy}>내용보기</small>
              <p className="validation-message">{errors.agreeServiceUse}</p>
            </div>
            <Policy showPolicy={showPolicy} togglePolicy={togglePolicy} />
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agreePersonalInfo"
                  checked={joinInfo.agreePersonalInfo}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>개인정보 수집 • 이용에 대한 동의 (필수)</span>
              </label>

              <small onClick={togglePrivacy}>내용보기</small>
              <p className="validation-message">{errors.agreePersonalInfo}</p>
            </div>

            <Privacy showPrivacy={showPrivacy} togglePrivacy={togglePrivacy} />
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agreeAd"
                  checked={joinInfo.agreeAd}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>전자적 전송매체를 이용한 광고성정보 수신 동의(선택)</span>
              </label>
            </div>

            <button className="btn mt-15 mb-15">동의하고 가입</button>
            <small className="mb-20">
              이미 IP KINGKONG 계정이 있다면? <Link to="/login"> 로그인</Link>
            </small>
            <ReCAPTCHA
              sitekey={
                process.env.RECAPCHA_KEY ||
                "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" /* 로컬 테스트키 */
              }
              onChange={onRecaptcha}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Join;
