import React from "react";
import "./ChargeInfo.scss";

const ChargeInfo = ({ togglePrice }) => {
  return (
    <div className="charge-info">
      <div>
        <img src="/images/main/cash-image.png" alt="cash-image" />
      </div>
      <div className="ml-10 mt-10">
        <p>캐시 충전은 고객센터(cs@ipkingkong.com)으로 문의주세요.</p>
        <p>충전시, 금액별로 캐시가 추가 지급됩니다.</p>
        <button className="charge-info__btn" onClick={togglePrice}>
          자세히 보기
        </button>
      </div>
    </div>
  );
};

export default ChargeInfo;
