import socket from "socket.io-client";

export default class Socket {
  constructor(info) {
    this.io = socket(ip_config.wss, {
      auth: (cb) => {
        cb({
          token: info.token,
        });
      },
    });

    this.io.on(`connect`, () => {
      console.log(`socket connected`, this.io.id);
      info.userIdx && this.io.emit(`join`, [`${info.userIdx}`]);
    });
    this.io.on(`connect_error`, (err) =>
      console.log(`socket error`, err.message)
    );
    this.io.on(`disconnect`, () =>
      console.log(`socket disconnected`, this.io.id)
    );

    this.io.on(`server:error`, (err) => console.log(`wss server error`, err));
  }

  onSync = (event, callback) => {
    if (!this.io.connected) {
      this.io.connect();
    }

    this.io.on(event, (message) => callback(message));
  };
}
