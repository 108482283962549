import { login } from "@/commonSlices/authSlice";
import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "./Login";

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: JSON.parse(localStorage.getItem(`ipkk_email`)) || "",
      password: "",
      remember: localStorage.getItem(`ipkk_email`) ? true : false,
    };
  }
  componentWillUnmount() {
    document.body.style.overflow = "unset";
  }

  render() {
    const { error, onShowModal } = this.props;
    const { email, password, remember } = this.state;
    return (
      <Login
        email={email}
        password={password}
        remember={remember}
        onChangeFieldValue={this.handleChangeFieldValue}
        onRemember={this.handleRemember}
        onSubmit={this.handleSubmit}
        onShowModal={onShowModal}
        error={error}
      />
    );
  }

  /**
   * 인풋변경
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * ID기억하기 클릭
   */
  handleRemember = () => {
    this.setState({ remember: !this.state.remember });
  };

  /**
   * 로그인정보 제출
   * @param {*} e
   */
  handleSubmit = async (e) => {
    const { email, password, remember } = this.state;
    const { onShowModal } = this.props;

    e.preventDefault();

    if (remember) {
      localStorage.setItem(`ipkk_email`, JSON.stringify(email));
    } else {
      localStorage.removeItem(`ipkk_email`);
    }

    /* then처리는 로그인 모달창을 닫아주기 위함. 에러처리는 slice 통해서 함 */
    this.props
      .login({ email, password })
      .unwrap()
      .then(() => {
        onShowModal && onShowModal();
      });
  };
}

const mapStateToProps = (state) => {
  return { error: state.auth.error };
};

export default connect(mapStateToProps, { login })(LoginContainer);
