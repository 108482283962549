import React, { Fragment } from "react";
import "./PatentPublication.scss";
import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import Tooltip from "@/commonModule/Tooltip/Tooltip";
import parse from "html-react-parser";
import KRGuide from "./fragments/Guide/KRGuide";
import USGuide from "./fragments/Guide/USGuide";
import CNGuide from "./fragments/Guide/CNGuide";
import JPGuide from "./fragments/Guide/JPGuide";
import { convertIntoLangName } from "@/util/common";

const PatentPublication = ({
  countryTypeList,
  patentList,
  hasChosen,
  onClickPreview,
  countryEnum,
  onChangeCountryEnum,
  numbers,
  onChangeFieldValue,
  onSearch,
  error,
  onCheckbox,
  onSelectOption,
  isLoading,
}) => {
  return (
    <div className="page-container patent-publication">
      <div className={"page__header"}>
        <div className="page__title">특허공보 번역</div>
        <small className="page__subtitle">
          번역할 특허공보를 특허번호로 찾아보세요
        </small>
      </div>
      <div>
        <RadioBtn
          name={"countryType"}
          btnList={countryTypeList}
          className={"mb-20"}
          callback={onChangeCountryEnum}
        />
        <div className={"mb-30"}>
          <div className="input-chaining">
            <textarea
              className={"form-control"}
              rows="3"
              id="numbers"
              name="numbers"
              placeholder="특허문헌 번호를 입력하세요. (최대 5개, 띄어쓰기로 구분)"
              value={numbers}
              onChange={onChangeFieldValue}
            />
            <div className="append">
              <button
                id="search"
                className={`btn btn-l h-full border-0 bg-kk ${
                  isLoading ? "disabled" : ""
                }`}
                type="button"
                onClick={onSearch}
              >
                <b>번호검색</b>
              </button>
            </div>
          </div>
          {error && <p className="validation-message">{error}</p>}
        </div>
        {isLoading ? (
          <div className="loading mg-center"></div>
        ) : patentList && patentList.length > 0 ? (
          <div className="card pd-0">
            <div className="card__main pd-0">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th
                      className={"border-top-0"}
                      width={60}
                      scope="col"
                      colSpan="1"
                    ></th>
                    <th className={"border-top-0"} scope="col">
                      <div className={"d-flex justify-content-end"}>
                        <button
                          className={`btn btn-s ${
                            hasChosen ? "" : "disabled"
                          }  `}
                          onClick={onClickPreview}
                        >
                          <em className={"fa fa-calculator mr-10"} />
                          선택문헌 번역 비용 계산하기
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patentList.map((patent, i) => (
                    <tr key={`patentPublication-${i}`}>
                      <td className={"text-align-center"}>
                        <div className="c-checkbox mr-0">
                          <label className="c-radio mr-0">
                            <input
                              type="radio"
                              name="searchResult"
                              onChange={() => onCheckbox(patent, i)}
                              defaultChecked={false}
                            />
                            <span className="fa fa-check" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className={"data-row"}>
                          <div className={"d-flex align-items-center"}>
                            <div className="mr-30">
                              <strong>
                                {convertIntoLangName(countryEnum).kName}
                              </strong>
                            </div>
                            <div className="mr-30">
                              <em className="fa fa-arrow-right" />
                            </div>
                            <div className="mr-30">
                              <select
                                id={`selectTransCode${i}`}
                                className="form-control"
                                onChange={() => onSelectOption(patent, i)}
                              >
                                {countryEnum === "KR" ? (
                                  <Fragment>
                                    <option value="EN">영어</option>
                                    <option value="ZH">중국어</option>
                                    <option value="JA">일본어</option>
                                    {/* 일본어, 중국어 옵션추가예정 */}
                                  </Fragment>
                                ) : (
                                  <option value="KO">한국어</option>
                                )}
                              </select>
                            </div>
                            <div className="">
                              <label className="c-radio">
                                <input
                                  type="radio"
                                  id={`coverageFull${i}`}
                                  name={`coverage${i}`}
                                  value="F"
                                  onChange={() => onSelectOption(patent, i)}
                                  defaultChecked
                                />
                                <span className="fa fa-circle" />
                                전문(full-text)
                              </label>
                              <label className="c-radio">
                                <input
                                  type="radio"
                                  id={`coverageKeyField${i}`}
                                  name={`coverage${i}`}
                                  value="P"
                                  onChange={() => onSelectOption(patent, i)}
                                />
                                <span className="fa fa-circle" />
                                명칭+요약+청구항
                              </label>
                            </div>
                          </div>
                          <div className={"mt-20 mb-20 text-bold"}>
                            {`${patent.patentNumber}(${patent.date})`}
                          </div>
                          <div>
                            <Tooltip
                              message={
                                patent.summary ? parse(patent.summary[0]) : null
                              }
                            >
                              <i className="fa fa-question-circle" /> 명칭 :{" "}
                              {patent.inventionTitle}
                            </Tooltip>
                          </div>
                          <div>
                            <span>출원번호 : </span>
                            <span>{patent.applicationNumber}</span>
                          </div>
                          <div>
                            <span>출원인 : </span>
                            <span>{patent.owner}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          pickPatentNumberGuide(countryEnum)
        )}
      </div>
    </div>
  );
};

function pickPatentNumberGuide(countryEnum) {
  switch (countryEnum) {
    case "KR":
      return <KRGuide />;
    case "US":
      return <USGuide />;
    case "CN":
      return <CNGuide />;
    case "JP":
      return <JPGuide />;
    default:
      throw new Error("wrong guide arg...");
  }
}

export default PatentPublication;
