export const MONTH_TERM_INFO = {
  ONE_MONTH: {
    label: "1개월",
    monthTerm: "1",
  },
  THREE_MONTH: {
    label: "3개월",
    monthTerm: "3",
  },
  SIX_MONTH: {
    label: "6개월",
    monthTerm: "6",
  },
  TWELVE_MONTH: {
    label: "12개월",
    monthTerm: "12",
  },
};
