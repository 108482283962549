import React from "react";
import "./Tooltip.scss";

const Tooltip = ({ children, message }) => {
  return (
    <div className="tooltip-container">
      {message && <div className="tooltip">{message}</div>}
      {children}
    </div>
  );
};

export default Tooltip;
