import React, { Fragment } from "react";
import "./BoardFaq.scss";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";
import { handleResetPaginationActive } from "@/util/common";

const BoardFaq = ({
  types,
  faqType,
  searchTitle,
  onChangeFieldValue,
  onClickDropdown,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="w-full">
      <div className="board-faq__search-form mb-20">
        <select
          className="select-box mr-20"
          id="faqType"
          name="faqType"
          value={faqType}
          onChange={onChangeFieldValue}
        >
          <option value="">전체</option>
          {types.map((v, i) => (
            <option key={`faqType-${i}`} value={v.code}>
              {v.codeName}
            </option>
          ))}
        </select>

        <div className="input-chaining">
          <input
            className={"form-control"}
            type="text"
            name="searchTitle"
            placeholder="검색할 질문을 입력해주세요."
            value={searchTitle}
            onChange={onChangeFieldValue}
          />
          <div
            className="append"
            onClick={() => {
              onFetchPage(1);
              handleResetPaginationActive();
            }}
          >
            <button className={"btn btn-s h-full border-0"}>조회</button>
          </div>
        </div>
      </div>
      <div className="table-pagination-container">
        <div className="table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th>유형</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={2}>검색결과가 없습니다.</td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <Fragment key={`tbody-${i}`}>
                    <tr
                      className="board-faq__tr board-faq__tr--stripe"
                      onClick={() => onClickDropdown(post)}
                    >
                      <td className="w-one-four">{post.faqTypeName}</td>
                      <td className="text-align-start text-kk text-bold">
                        {post.title}
                      </td>
                    </tr>
                    {post.isClicked ? (
                      <tr>
                        <td
                          colSpan={2}
                          className="w-full text-align-start pd-25"
                        >
                          {parse(post.contents)}
                        </td>
                      </tr>
                    ) : (
                      <tr></tr>
                    )}
                  </Fragment>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default BoardFaq;
