import React, { Component } from "react";
import MyDocument from "./MyDocument";
import { cloneDeep } from "lodash";
import { calculateCash, httpRequest, TRANS_CODE_DATA } from "@/util/common";

class MyDocumentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      file: {},
      isSpecification: false,
      chosen: {},
      selOrgLang: "",
      selTransLang: "",
      isLoading: false,
      errors: { step2: "" },
    };
  }
  render() {
    const {
      currentStep,
      file,
      isSpecification,
      chosen,
      selOrgLang,
      selTransLang,
      isLoading,
      errors,
    } = this.state;
    return (
      <MyDocument
        currentStep={currentStep}
        onMoveStep={this.handleMoveStep}
        file={file}
        onDrop={this.handleOnDrop}
        isSpecification={isSpecification}
        onRemove={this.handleOnRemove}
        chosen={chosen}
        onClickTransType={this.handleClickTransType}
        selOrgLang={selOrgLang}
        selTransLang={selTransLang}
        isLoading={isLoading}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
      />
    );
  }

  /**
   * 유효성 체크
   * @param stepNumber
   * @return {boolean}
   */
  checkValidation(stepNumber) {
    let result = true;
    const targetStep = document.querySelector(
      `.step-info.step-info-${stepNumber}`
    );
    const targetNodeList = document.querySelectorAll(
      `.step-${stepNumber} [required]`
    );

    targetStep.setAttribute("aria-invalid", "false");

    for (let i = 0; i < targetNodeList.length; i++) {
      const targetNode = targetNodeList[i];
      if (!targetNode.value) {
        targetNode.setAttribute("aria-invalid", "true");
        targetStep.setAttribute("aria-invalid", "true");
        result = false;
        break;
      } else {
        targetNode.setAttribute("aria-invalid", "false");
      }
    }

    return result;
  }

  /**
   * 스탭 이동 동작
   * @param e
   */
  handleMoveStep = (e) => {
    const id = e.target.id;
    const { currentStep } = this.state;
    let stepNumber = cloneDeep(currentStep);
    const slideBCR = document.querySelector(".step-1").getBoundingClientRect();
    const slider = document.querySelector(".step-contents-wrapper");

    if (id === "nextStep") {
      if (!this.checkValidation(stepNumber)) return;
      if (stepNumber < 3) {
        stepNumber++;
      }
    }
    if (id === "prevStep") {
      if (stepNumber > 1) {
        stepNumber--;
      }
    }
    slider.scrollLeft = slideBCR.width * (stepNumber - 1);

    this.setState({
      currentStep: stepNumber,
    });
  };

  /**
   * 스텝1 번역언어 선택하기
   * 원문 언어를 선택하면 번역언어목록에서 해당언어를 숨긴다
   * 이전버튼으로 와서 언어변경 시 초기화한다
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({
      currentStep: 1,
      file: {},
      isSpecification: false,
      chosen: {},
      isLoading: false,
      errors: { step2: "" },
      [e.target.name]: e.target.value,
    });
  };

  /**
   * 스텝2 dropzone 파일업로드하기
   * @param {*} files
   */
  handleOnDrop = async (files) => {
    const { selOrgLang, selTransLang } = this.state;
    this.setState({ isLoading: true });
    try {
      /** 1. 파일 업로드 */
      const data = new FormData();
      data.append("file", files[0]);
      const { data: { fileIdx, fileName } } = await httpRequest.post(`file/upload`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      /** 2. 명세서형식 확인 */
      const {
        data: { status },
      } = await httpRequest.post(`patent/validationStatement`, {
        orgLang: selOrgLang,
        fileIdx,
        fileName,
      });
      if (!status) throw new Error(`명세서 형식이 아닙니다.`);

      /** 3. 파일 글자수 반환 */
      const { data: fileCharCount } = await httpRequest.post(
        `patent/mydoc/count?textMode=true`,
        {
          orgLang: selOrgLang,
          fileIdx,
          fileName,
        }
      );

      /**
       * 번역비용계산 step3 정보 생성
       */
      let chosen = {
        [fileIdx]: {
          fileIdx,
          charCount: fileCharCount[fileIdx],
          documentId: fileName.split(".")[0],
          documentNo: files[0].name.split(".")[0],
          documentName: files[0].name,
          orgLang: selOrgLang,
          transLang: selTransLang,
          transCoverage: "F",
          costUnit: TRANS_CODE_DATA[selOrgLang + selTransLang]["unitCost"],
          /* 선택한 타입(디폴트:BASIC)*/
          transType: "AA",
          chargePoint: calculateCash(
            fileCharCount[fileIdx],
            selOrgLang,
            selTransLang,
            "AA"
          ),
        },
      };

      document
        .querySelector(`.step-info.step-info-2`)
        .setAttribute("aria-invalid", "false");
      this.setState({
        file: files[0],
        isSpecification: true,
        chosen,
        errors: {},
      });
    } catch (err) {
      document
        .querySelector(`.step-info.step-info-2`)
        .setAttribute("aria-invalid", "true");
      
      let errorMessage;
      if( err && err.response ) {
        errorMessage = err.response.status === 406 ? err.response.data.message : '오류가 발생했습니다. 다시 시도해주세요.';
      } else {
        errorMessage = err.message;
      }
      
      this.setState({
        file: [],
        isSpecification: false,
        chosen: {},
        errors: { step2: errorMessage },
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  /**
   * 스텝2 업로드파일 제거하기
   */
  handleOnRemove = () => {
    this.setState({
      file: {},
      isSpecification: false,
      chosen: {},
      errors: {},
    });
  };

  /**
   * 스텝3
   * 번역비용 금액 라디오버튼 BASIC/SPECIAL/PREMIUM 중 선택하기
   * @param {*} e
   */
  handleClickTransType = (e, post) => {
    const chosen = { ...this.state.chosen };
    chosen[post.fileIdx].transType = e.target.value;
    chosen[post.fileIdx].chargePoint = e.target.dataset.chargepoint;

    this.setState({ chosen });
  };
}

export default MyDocumentContainer;
