import React from "react";

const InicisForm = ({ reqField, goodname }) => {
  return (
    <form id="SendPayForm_id" name="" method="POST">
      <input type="hidden" name="version" value={reqField.version} />
      <input type="hidden" name="mid" value={reqField.mid} />
      <input type="hidden" name="goodname" value={goodname} />
      <input type="hidden" name="oid" value={reqField.oid} />
      <input type="hidden" name="price" value={reqField.price} />
      <input type="hidden" name="currency" value={reqField.currency} />
      <input type="hidden" name="buyername" value={reqField.buyerName} />
      <input type="hidden" name="buyertel" value={reqField.buyerTel} />
      <input type="hidden" name="buyeremail" value={reqField.buyerMail} />
      <input type="hidden" name="timestamp" value={reqField.timestamp} />
      <input type="hidden" name="signature" value={reqField.signature} />
      <input
        type="hidden"
        name="returnUrl"
        value={
          /*  "https://localhost:7000/api/order/translate/inicis" */
          reqField.returnUrl
        }
      />
      <input type="hidden" name="mKey" value={reqField.mkey} />
      <input
        type="hidden"
        name="gopaymethod"
        value={reqField.goPayMethod}
      />{" "}
      {/* 결제수단 */}
      <input type="hidden" name="offerPeriod" value="" />
      <input
        type="hidden"
        name="acceptmethod"
        value={reqField.acceptMethod}
      />{" "}
      {/* 결제창 화면옵션 */}
      <input type="hidden" name="languageView" value="" />
      <input type="hidden" name="charset" value="" />
      <input type="hidden" name="payViewType" value="" />
      <input type="hidden" name="quotabase" value={reqField.cardQuotaBase} />
      <input type="hidden" name="ini_onlycardcode" value="" />
      <input type="hidden" name="ini_cardcode" value="" />
      <input type="hidden" name="ansim_quota" value="" />
      <input type="hidden" name="vbankRegNo" value="" />
      {/* 리턴 URL까지 전달할 데이터 */}
      <input
        type="hidden"
        name="merchantData"
        value={encodeURIComponent(
          JSON.stringify({
            ...reqField.merchantData,
            successUrl: `${reqField.siteDomain}/payment/orderSuccess`,
            failUrl: `${reqField.siteDomain}/payment/orderFail`,
            cancelUrl: `${reqField.siteDomain}/payment/orderPayment`,
          })
        )}
      />
    </form>
  );
};

export default InicisForm;
