import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import BoardFaq from "./BoardFaq";

class BoardFaqContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      faqType: "",
      searchTitle: "",
      boardFaqs: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchBoardFaqs(1);
  }

  render() {
    const { types, faqType, searchTitle, boardFaqs, postsPerPage, total } =
      this.state;
    return (
      <BoardFaq
        types={types}
        faqType={faqType}
        searchTitle={searchTitle}
        onChangeFieldValue={this.handleChangeFieldValue}
        onClickDropdown={this.clickDropdown}
        currentPosts={boardFaqs}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchBoardFaqs}
      />
    );
  }

  /**
   * 서버에서 FAQ 정보 받아오기
   */
  fetchBoardFaqs = async (number) => {
    const { faqType, searchTitle, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total, types },
      } = await httpRequest.get(
        `board/faq?page=${number}&size=${postsPerPage}&faqType=${faqType}&title=${searchTitle}`
      );

      const boardFaqs = items.map((v) => {
        v.isClicked = false;
        return v;
      });

      this.setState({ ...this.state, boardFaqs, types, total });
    } catch (err) {
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 클릭했을 때 FAQ 드롭다운 보여주기
   * @param {*} post
   */

  clickDropdown = (post) => {
    const boardFaqs = this.state.boardFaqs.map((faq) => {
      if (faq.boardContentsIdx === post.boardContentsIdx) {
        return { ...faq, isClicked: !faq.isClicked };
      }
      return faq;
    });
    this.setState({ boardFaqs });
  };

  /**
   *  타입, 검색타이틀 인풋 변경
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default BoardFaqContainer;
