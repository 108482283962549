import { combineReducers } from "redux";
import authSlice from "./commonSlices/authSlice";
import socketSlice from "./commonSlices/socketSlice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  socket: socketSlice.reducer,
});

export default rootReducer;
