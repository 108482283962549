import React from "react";
import { Link } from "react-router-dom";
const Send = ({ email, onChangeFieldValue, onSubmit }) => {
  return (
    <div className="page-container">
      <div className="page__title">비밀번호 찾기</div>

      <div className="divider"></div>
      <form className="w-half mg-center" onSubmit={onSubmit}>
        <div className="card">
          <div className="card__header">비밀번호 찾기</div>
          <div className="card__main">
            <fieldset>
              <div className="form-group">
                <label>이메일 *</label>
                <div className="input-chaining">
                  <input
                    className={"form-control"}
                    type="email"
                    name="email"
                    placeholder="이메일을 입력해주세요."
                    value={email}
                    onChange={onChangeFieldValue}
                    required
                  />
                  <div className="append">
                    <span className="fas fa-envelope dummy-btn bg-w h-full" />
                  </div>
                </div>
                <small>
                  가입 시 등록했던 이메일 입력시, 비밀번호 변경 URL이
                  전송됩니다.
                </small>
                <button className="btn mt-15">비밀번호 변경 URL 전송</button>
              </div>
              <div className="mt-5 d-flex justify-content-space-between">
                <Link to="/login">로그인</Link>
                <Link to="/join/form">회원가입</Link>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Send;
