import React from "react";
import './KeywertPayment.scss';

const KeywertPayment = ({
    email, mobile, goodname, price, priceCommafy, priceSimple,
    handleChangeEmail, handleChangeMobile, handleChangeGoodname, handleChangePrice, handleClickPayment
}) => {
    return (
        <div className="keywert-payment-page">
            <div className="keywert-payment-page__header">
                <span className="header-title">키워트 카드 결제 페이지</span>
                <span className="header-info">(관리자 전용 페이지)</span>
            </div>

            <div className="keywert-payment-page__body">
                <div className="body-info">이메일</div>
                <input type="text" className="body-input" placeholder="test@wert.co.kr" value={ email } onChange={ handleChangeEmail }/>
                <div className="body-info">휴대폰번호</div>
                <input type="text" className="body-input" placeholder="숫자만 입력해주세요. ex) 01012345678" value={ mobile } onChange={ handleChangeMobile }/>
                <div className="body-info">상품명</div>
                <input type="text" className="body-input" placeholder="상품명을 입력해주세요." value={ goodname } onChange={ handleChangeGoodname }/>
                <div className="body-info">가격</div>
                <div>
                    <input type="number" className="body-input" placeholder="가격을 입력해주세요." value={ price } onChange={ handleChangePrice }/>
                    <span className="price-commafy">{ priceCommafy }원</span>
                    <span className="price-simple">({ priceSimple }원)</span>
                </div>
            </div>

            <div className="keywert-payment-page__footer">
                <button className="btn-inicis" disabled={ (!goodname) || (price <= 0) } onClick={handleClickPayment}>결제하기</button>
            </div>
        </div>
    )
}

export default KeywertPayment;