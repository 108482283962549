import React from "react";
import { Link } from "react-router-dom";
import "./BoardNewsDtl.scss";
import parse from "html-react-parser";
import Loader from "@/commonModule/Loader/Loader";

const BoardNewsDtl = ({ post, attachFile, onFileDownload, isLoading }) => {
  return isLoading ? (
    <Loader />
  ) : (
    <div className="card w-full">
      <div className="board-news-dtl__header">
        <div>{post.title}</div>
        <div>
          <span>
            <strong>작성일</strong>
            {post.regDttm}
          </span>
        </div>
      </div>
      <div className="divider"></div>
      <div className="board-news-dtl__main">{parse(String(post.contents))}</div>
      <div className="divider"></div>
      <div className="board-news-dtl__footer">
        <div className="attach-file">
          <label>첨부파일</label>
          {attachFile.map((v, i) => (
            <div key={`dtl-file-${i}`} className="attach-file__item">
              <Link
                to="#"
                onClick={() => onFileDownload(v.fileIdx, v.orgFileName)}
              >
                {v.orgFileName}
              </Link>
            </div>
          ))}
        </div>
        <Link to="/board/news">
          <button className="list-btn">
            <i className="fas fa-list-ul"></i>
            <span>목록</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BoardNewsDtl;
