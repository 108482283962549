import React from "react";
import { Link } from "react-router-dom";
import "./RegistFail.scss";

const RegistFail = () => {
  return (
    <section className="regist-fail">
      <div className="regist-fail__logo">
        <em className="fas fa-times"></em>
      </div>
      <div className="regist-fail__title">가입인증에 실패하였습니다.</div>
      <div className="regist-fail__subtitle">
        <Link className="text-kk text-bold" to="/sendAuthMail">
          <em className="fas fa-envelope"></em> 인증 이메일 재발송하기
        </Link>
      </div>
      <Link to="/" className="btn btn-xl">
        <span>홈으로 가기</span>
      </Link>
    </section>
  );
};

export default RegistFail;
