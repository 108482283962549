import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import KeywertPayment from "@/serviceModule/KeywertPayment/KeywertPayment";
import InicisForm from "@/serviceModule/OrderPayment/fragments/InicisForm";
import { dynamicIncludeScript, httpRequest } from "@/util/common";

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

@connect(mapStateToProps, null)
export default class KeywertPaymentContainer extends Component {
    constructor(props) {
        super(props);

        dynamicIncludeScript({
            src: "https://stdpay.inicis.com/stdjs/INIStdPay.js",
            async: false
        });

        this.accessAllowedUserList = [ 'kingkong@wert.co.kr' ];

        this.state = {
            email: '',
            mobile: '',
            goodname: 'keywert 사용료',
            price: 0,
            priceCommafy: '0',
            priceSimple: '0',
            reqField: {}
        }
    }

    componentDidMount() {
        const { user } = this.props.auth;
        if (user && this.checkAccessAllow(user)) {
            this.fetchUserInfo();
        } else {
            window.location.href = '/';
        }
    }

    fetchUserInfo = async () => {
        const { user: { idx } } = this.props.auth;
        try {
            const { data: { mail, hphone } } = await httpRequest.get(`user/${idx}`);
            this.setState({
                email: mail,
                mobile: hphone
            });
        } catch (err) {
            window.location.href = '/';
        }
    }

    render() {
        return (
            <Fragment>
                <InicisForm
                    reqField={this.state.reqField}
                    goodname={this.state.goodname || undefined}
                />
                <KeywertPayment
                    email={this.state.email}
                    mobile={this.state.mobile}
                    goodname={ this.state.goodname }
                    price={ this.state.price }
                    priceCommafy={ this.state.priceCommafy }
                    priceSimple={ this.state.priceSimple }
                    handleChangeEmail={this.handleChangeEmail.bind(this)}
                    handleChangeMobile={this.handleChangeMobile.bind(this)}
                    handleChangeGoodname={ this.handleChangeGoodname.bind(this) }
                    handleChangePrice={ this.handleChangePrice.bind(this) }
                    handleClickPayment={ this.handleClickPayment.bind(this) }
                />
            </Fragment>
        )
    }

    checkAccessAllow(user) {
        const { mail } = user;
        if (!mail) {
            return false;
        } else {
            const matchUserList = this.accessAllowedUserList.filter(allowedMail => allowedMail === mail);
            return matchUserList.length > 0;
        }
    }

    handleChangeEmail(e) {
        this.setState({
            email: e.target.value
        })
    }

    handleChangeMobile(e) {
        this.setState({
            mobile: e.target.value
        })
    }

    handleChangeGoodname(e) {
        this.setState({
            goodname: e.target.value
        })
    }

    handleChangePrice(e) {
        const price = e.target.value * 1;
        this.setState({
            price: price,
            priceCommafy: this.convertPriceCommafy(price),
            priceSimple: this.convertPriceSimple(price)
        })
    }

    convertPriceCommafy(price) {
        return price && price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    convertPriceSimple(price) {
        const num1 = price % 10000;
        const num2 = ((price - num1) / 10000) % 10000;
        const num3 = ((price - num2 * 10000 - num1) / 100000000) % 100000000;
        let priceString = '';
        if (num3 !== 0) {
            priceString += `${num3}억`;
        }
        if (num2 !== 0) {
            priceString += `${num2}만`;
        }
        if (num1 !== 0) {
            priceString += `${num1}`;
        }
        return priceString;
    }

    handleClickPayment = async () => {
        const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        const hphoneRegex = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;

        if (!emailRegex.test(this.state.email)) {
            alert('이메일 형식을 확인해주세요.');
            return;
        }

        if (!hphoneRegex.test(this.state.mobile)) {
            alert('휴대폰번호 형식을 확인해주세요.');
            return;
        }

        const orderData = {
            point: 0,
            price: this.state.price,
            orderMail: this.state.email,
            orderPhone: this.state.mobile,
            orderItems: [
                {
                    charCount: 437,
                    chargePoint: 3583,
                    costUnit: {
                        AA: 8.2,
                        AB: 20,
                        AC: 37.8
                    },
                    documentDate: "2016.06.22",
                    documentId: "kr20160003066u",
                    documentName: "KR 2016-0002154 U(2016.06.22)",
                    documentNo: "KR 2016-0002154 U",
                    orgLang: "KO",
                    transCoverage: "P",
                    transLang: "EN",
                    transReqType: "P",
                    transState: "A",
                    transType: "AA"
                }
            ]
        };

        try {
            const { data } = await httpRequest.post("payment/translate/inicis", orderData);
            this.setState({ reqField: data }, () => {
                INIStdPay.pay('SendPayForm_id');
            })
        } catch (err) {
            alert('오류가 발생했습니다. 다시 시도해주세요.');
            return;
        }
    }
}