import React from "react";
import "./Policy.scss";

const Policy = ({ showPolicy, togglePolicy }) => {
  const showHideClassName = showPolicy
    ? `policy-modal display-block`
    : `policy-modal display-none`;

  return (
    <div className={showHideClassName} onClick={togglePolicy}>
      <section
        className="policy-modal-contents"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="policy-modal-contents__header">
          <span>이용약관</span>
          <div
            className="policy-modal-contents__close-btn"
            onClick={togglePolicy}
          >
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="policy-modal-contents__main">
          <h4>제 1조 (목적)</h4>
          <p>
            본 약관은 ㈜워트인텔리전스 (이하 "워트"라 합니다)가 제공하는
            “IP킹콩” (이하 “IP킹콩”라 합니다) 및 IP킹콩 관련 제반 서비스의
            이용과 관련한 조건과 절차 및 기타 필요한 사항을 규정함을 목적으로
            한다.
          </p>
          <h4>제 2조 (용어의 정의)</h4>
          <p>본 약관에서 사용하는 용어의 정의는 다음 각 호와 같다.</p>
          <ul className="mb-15">
            <li>
              'IP킹콩 서비스'라 함은 '워트'가 온라인으로 '회원'에게 제공하는
              특허번역 서비스를 말한다.
            </li>
            <li>
              '이용자'라 함은 '워트’에서 제공하는 서비스를 이용하는 자(또는
              이용하고자 하는 자)를 말한다.
            </li>
            <li>
              'ID'와 '비밀번호'라 함은 '이용자'가 'IP킹콩 서비스'를 이용함에
              있어 이용자 식별과 확인을 위해 '워트'가 부여하거나 '회원'이 선택한
              문자와 숫자의 조합을 말한다.
            </li>
            <li>
              '회원’이라 함은 ‘워트’에 개인 정보를 제공하여 회원등록을 한
              자로서, ‘IP킹콩 서비스’의 정보를 지속적으로 제공받으며, 서비스를
              계속적으로 이용할 수 있는 자를 말한다.
            </li>
            <li>
              '유료회원'이라 함은 ‘워트’에 이용요금을 지불하고 'IP킹콩 서비스'를
              이용하는 회원을 말한다.
            </li>
            <li>
              '공지사항'이라 함은 '워트'가 '이용자'에게 단말기를 통하여 공지하는
              내용을 말한다.
            </li>
            <li>
              '킹콩캐시'라 함은 이용자가 ‘IP킹콩 서비스’에서 다양한 서비스나
              혜택을 받는데 사용하기 위하여 ‘워트’가 발행, 관리하는 마일리지
              형태의 선불식 전자지급수단을 말합니다.
            </li>
          </ul>
          <h4>제 3조 (약관의 범위 및 변경)</h4>
          <ul className="mb-15">
            <li>
              본 약관에 첨부되었거나 첨부될 내용 및 'IP킹콩 서비스'의
              '공지사항'을 통해 공지된 내용은 본 계약의 일부로서 효력을 갖는다.
            </li>
            <li>
              '워트'는 약관의 내용에 변경이 있을 경우에는 그 내용을 '회원'에게
              서면 통보하거나 'IP킹콩 서비스'의 '공지사항'에 게시하며, 통보 또는
              공지 후 2주간 '회원'의 이의가 없으면 그 변경에 동의한 것으로 본다.
              ‘회원’이 약관의 변경사항을 확인하지 아니하여 발생하는 ‘회원’의
              피해는 ‘워트’에서 책임지지 않는다.
            </li>
            <li>
              본 약관과 관련하여 '회원'의 임직원의 행위는 '회원'의 행위로 본다.
            </li>
            <li>
              '워트’는 필요한 경우 ‘IP킹콩 서비스’ 내의 개별 항목에 대하여
              개별약관 또는 서비스별 운영정책을 정할 수 있으며, 이 약관과
              서비스별 운영정책의 내용이 상충되는 경우에는 서비스별 운영정책의
              내용을 우선하여 적용한다.
            </li>
          </ul>
          <h4>제 4조 (이용신청 및 승인)</h4>
          <ul className="mb-15">
            <li>
              'IP킹콩 서비스'의 이용은 '회원'이 서면이나 전산망을 통해
              이용신청을 한 후 '워트'의 승인으로 이루어진다.
            </li>
            <li>
              회원 가입은 반드시 실명으로만 할 수 있으며, 회사는 본인인증조치를
              할 수 있다. 회원은 회사의 확인 조치에 대하여 협력하여야 하며, 이를
              준수하지 아니할 경우 회사는 회원의 등록정보가 진정하지 않은 것으로
              간주하고 승인을 거부할 수 있다.
            </li>
            <li>
              진정한 정보를 제공하지 않은 회원은 IP킹콩 서비스 이용과 관련하여
              아무런 권리를 주장할 수 없으며, 타인의 명의를 도용하여 이용신청을
              한 경우 회원의 모든 정보는 삭제되며, 관계법령에 따라 처벌을 받을
              수 있다.
            </li>
            <li>
              '워트'는 이용에 대한 승인과 동시에 '회원'에게 고유의 'ID'와
              '비밀번호'를 부여하여야 한다.
            </li>
            <li>
              '이용자'는 'ID'와 '비밀번호'를 입력한 후 'IP킹콩 서비스'를 이용할
              수 있다.
            </li>
            <li>
              '회원'은 이용신청과 관련된 기재사항 변경이 있는 경우, 이를 즉시
              '워트'에 통지한다.
            </li>
            <li>
              회원의 개인정보의 보호 및 사용에 대해서는 ‘IP킹콩 서비스’의
              개인정보취급방침이 적용된다.
            </li>
          </ul>
          <h4>제 5조 ('IP킹콩 서비스' 제공시간)</h4>
          <p>
            'IP킹콩 서비스' 제공시간은 평일 09:00부터 18:00까지로 하고 토,
            일요일과 공휴일 등 '워트'의 비영업일에는 제공할 의무를 지지
            아니한다.
          </p>
          <h4>제 6조 (장비구입 및 설치)</h4>
          <p>
            '회원'은 'IP킹콩 서비스' 이용에 필요한 단말기, 통신회선 등의 모든
            장비를 '회원'의 비용으로 구입, 설치하고 이에 대한 관리책임을 갖는다.
          </p>
          <h4>제 7조 ('ID' 등의 관리)</h4>
          <p>
            '회원'은 본인 이외의 자가 서비스를 이용하지 못하도록 'ID' 및
            '비밀번호'를 관리하여야 하며, 그 관리소홀로 인한 '정보서비스'의
            부당이용 등의 손해가 발생한 경우 그 결과에 대한 모든 책임을 진다.
          </p>
          <h4>제 8조 (이용의 제한)</h4>
          <ul className="mb-15">
            <li>
              '회원'은 검색 및 번역한 자료를 복사, 복제, 개조하여 제3자에게
              제공, 판매 또는 누설하여서는 아니된다.
            </li>
            <li>
              '회원'은 '워트'로부터 서면에 의한 사전 승인을 받은 경우를
              제외하고는 검색 및 번역한 자료를 전산장비에 저장, 보존하여서는
              아니한다.
            </li>
          </ul>
          <h4>제 9조 ('회원'의 지위승계)</h4>
          <p>
            이용계약의 양도 등 '유료회원'의 지위 승계는 '워트'의 사전승인을
            받아야 한다.
          </p>
          <p>
            다만, 각 호의 경우에는 그 승계사실을 증명하는 서류를 첨부하여
            “워트”에 통보한 후 계속 이용할 수 있다.
          </p>
          <ul className="mb-15">
            <li>개인기업의 대표자가 사망하여 그 지위를 상속받은 경우</li>
            <li>
              둘 이상의 법인이 하나의 법인으로 합병되어 신설법인이 그 사용권을
              인수한 경우
            </li>
            <li>
              법인 상호간 흡수, 합병하여 존속법인이 그 사용권을 인수한 경우
            </li>
            <li>
              하나의 법인이 둘 이상의 법인으로 분할하여 분할된 법인 중 하나가
              사용권을 인수한 경우
            </li>
          </ul>
          <h4>제 10조 (정보제공의 중지)</h4>
          <p>
            '워트'는 다음 각 호의 사유가 발생한 경우에 제5조에도 불구하고
            정보제공 중지사유 및 일자를 서면통지한 후 정보제공을 중지할 수 있다.
          </p>
          <ul className="mb-15">
            <li>이용료를 2회 이상 납입하지 아니한 경우</li>
            <li>'회원'이 본 계약을 위반한 경우</li>
            <li>'워트'의 사정으로 정보를 제공할 수 없는 경우</li>
            <li>
              '워트' 내규에 의거 '회원'에 대한 정보제공 제한사유가 발생한 경우
            </li>
            <li>
              '회원'의 '정보서비스' 이용이 '워트'의 영업상 또는 다른 업무에
              문제가 있다고 판단된 경우
            </li>
          </ul>
          <h4>제 11조 (정보제공의 재개)</h4>
          <ul className="mb-15">
            <li>
              ‘유료회원’의 경우, 미납 이용료와 제13조 제4항에 의한 가산금이
              완납되어 중지사유가 해소된 때에는 '워트'는 즉시 정보제공을
              재개한다.
            </li>
            <li>
              제10조에 의하여 정보제공이 중지된 '회원'으로부터 정보제공 재개
              요청이 있는 경우에는 '워트'는 그 타당성을 검토한 후 정보제공을
              재개한다.
            </li>
          </ul>
          <h4>제 12조 (이용료의 계산)</h4>
          <ul className="mb-15">
            <li>
              '유료회원'은 'IP킹콩 서비스'의 이용요금을 번역문서별, 일정금액별
              또는 연, 반기, 분기 혹은 월 단위로 납부하여야 한다.
            </li>
            <li>
              이용기간이 월 미만인 경우에는 그 이용료를 일할 계산(천원 미만
              절사)한다.<p>- 일할 계산방식 : 이용료 x (사용일수 ÷ 30)</p>
            </li>
          </ul>
          <h4>제 13조 (이용료의 청구 및 납부)</h4>
          <ul className="mb-15">
            <li>
              이용료는 온라인 또는 오프라인으로, ‘워트’가 승인하는 결제수단에
              의하여 납부한다.
            </li>
            <li>
              '워트'는 이용료 청구서를 '회원'에게 웹사이트나 이메일로 발송한다.
            </li>
            <li>
              '유료회원'은 즉시 또는 청구서에 지정된 납입기일까지 '워트'가
              지정한 결제 수단으로 납부한다. 다만, 지정된 기일이 공휴일인
              경우에는 익영업일을 납입 기일로 한다.
            </li>
            <li>
              '유료회원'이 청구서의 지정기일까지 이용료를 납입하지 아니한 때에는
              그 이용료의 100분의 3이 가산금으로 부과될 수 있다.
            </li>
          </ul>
          <h4>제 14조 (옵션정보 서비스의 이용)</h4>
          <ul className="mb-15">
            <li>
              '회원'이 옵션서비스를 이용하고자하는 경우에는 별도의 신청 후
              계약을 맺어야 한다.
            </li>
            <li>
              옵션서비스를 이용하기 위해서는 'IP킹콩 서비스' 이용요금 외에 옵션
              서비스 이용료를 별도로 납부하여야 한다.
            </li>
          </ul>
          <h4>제 15조 (면책사항)</h4>
          <ul className="mb-15">
            <li>
              천재지변, 국가비상사태, 화재 등 부득이한 사유로 인하여 'IP킹콩
              서비스' 제공이 중지된 경우에는 '워트'는 어떠한 책임도 지지
              아니한다.
            </li>
            <li>
              제4조 6항의 통지를 해태함으로써 입게 되는 '회원'의 불이익에
              대하여는 '워트’는 어떠한 책임도 지지 아니한다.
            </li>
            <li>
              '워트'는 'IP킹콩 서비스'의 정확성을 기하기 위하여 최선의 노력을
              다하지만, 제공되는 정보의 정확성 및 진실성에 대하여 어떠한 내용의
              진술 및 보장도 하지 않으며, 어떠한 책임도 지지 아니한다.
            </li>
            <li>
              시스템상의 장애로 인하여 정보제공이 지체 또는 중지되는 경우에는
              '워트'는 어떠한 책임도 지지 아니한다. 단, 그러한 시스템상의 장애가
              '워트'의 고의 또는 중과실로 인한 경우에는 그러하지 아니하다.
            </li>
            <li>
              제3자의 고의 또는 중과실에 의하여 정보제공이 사실과 다르게 제공된
              경우에는 '워트'는 어떠한 책임도 지지 아니한다.
            </li>
            <li>
              '회원'이 본 계약에 의하여 제공받은 정보를 이용하여 행한 판단이나
              행위결과에 대하여 '워트'는 어떠한 책임도 지지 아니한다.
            </li>
          </ul>
          <h4>제 16조 (법령 준용)</h4>
          <p>
            'IP킹콩 서비스' 이용에 관하여 본 계약에 정하지 않은 사항에 대하여는
            동 관계법령을 준용한다.
          </p>
          <h4>제 17조 (합의관할)</h4>
          <p>본 계약과 관련된 소송의 관할법원은 서울중앙지방법원으로 한다.</p>
        </div>
      </section>
    </div>
  );
};

export default Policy;
