import {
  calculateCash,
  checkIfExpired,
  convertDateFormat,
  httpRequest,
  TRANS_CODE_DATA,
} from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import TransHistory from "./TransHistory";
import { MONTH_TERM_INFO } from "./TransHistoryInfo";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clientLogout } from "@/commonSlices/authSlice";

toast.configure();
class TransHistoryContainer extends Component {
  constructor(props) {
    const d = new Date();
    d.setMonth(d.getMonth() - 3);
    super(props);
    this.monthTermList = Object.keys(MONTH_TERM_INFO).map((key) => {
      const obj = MONTH_TERM_INFO[key];
      return {
        label: obj.label,
        value: obj.monthTerm,
        defaultChecked: key === `THREE_MONTH`,
      };
    });

    const queryOrderNo =
      this.props.location.query && this.props.location.query.orderNo;

    this.state = {
      fromDate: d,
      endDate: new Date(),
      orderNo: queryOrderNo || "",
      histories: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchTransHistory(1);
  }

  render() {
    const { orderNo, fromDate, endDate, histories, postsPerPage, total } =
      this.state;

    return (
      <TransHistory
        monthTermList={this.monthTermList}
        onChangeMonthTerm={this.handleMonthTerm}
        fromDate={fromDate}
        endDate={endDate}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        orderNo={orderNo}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={histories}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchTransHistory}
        onFileDownload={this.handleFileDownload}
        onOrderPaymentForReview={this.handleOrderPaymentForReview}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      />
    );
  }

  /**
   * 서버에서 MY번역내역 받아오기
   */
  fetchTransHistory = async (number) => {
    const {
      auth: { user },
      history,
    } = this.props;
    const { fromDate, endDate, postsPerPage, orderNo } = this.state;
    try {
      const {
        data: { total, items },
      } = await httpRequest.get(
        `translate/${
          user.idx
        }?page=${number}&size=${postsPerPage}&from=${convertDateFormat(
          fromDate
        )}&to=${convertDateFormat(endDate)}&orderNo=${orderNo}`
      );
      this.setState({ histories: items, total });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 라디오버튼 선택 시 조회기간 변경하기
   * @param {*} e
   */
  handleMonthTerm = (e) => {
    const d = new Date();
    d.setMonth(d.getMonth() - parseInt(e.target.value));
    this.setState({ fromDate: d });
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ endDate: date });
  };

  /**
   * 주문번호 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * pdf, word 다운로드 받기
   */
  handleFileDownload = async (
    endDate,
    transReqIdx,
    documentId,
    fileName,
    type
  ) => {
    const isExpired = checkIfExpired(endDate);
    const url = `${
      ip_config.baseUrl
    }/file/download?requestId=${transReqIdx}&documentId=${documentId}&fileName=${fileName}&docx=${
      type === "PDF" ? false : true
    }`;

    if (isExpired) {
      toast.error("다운로드가능 기한이 초과되었습니다.");
      return;
    }
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.append(link);
      link.click();
    } catch (err) {
      toast.error("다운로드 중 에러가 발생하였습니다.");
    }
  };

  /**
   * 추가 검수신청하기(번역가검수, 변리사검수)
   * @param {*} post
   * @param {*} type
   */
  handleOrderPaymentForReview = (post, transType, calcOption) => {
    const { history } = this.props;
    
    let chosen = {
      [post.fileIdx]: {
        fileIdx: post.files[0].idx,
        charCount: post.charCount,
        documentDate: post.documentDate || undefined,
        documentId: post.documentId,
        documentNo: post.documentNo,
        documentName: post.transReqType === 'N' ? `${post.documentNo}${post.files.length > 0 ? `.${post.files[0].ext}` : ''}` : `${post.documentNo}${ post.documentDate ? `(${post.documentDate})` : "" }`,
        orgLang: post.orgLang,
        transLang: post.transLang,
        transCoverage: post.transCoverage,
        costUnit: TRANS_CODE_DATA[post.orgLang + post.transLang]["unitCost"],
        /* 선택한 타입 */
        transType: post.transReqType === 'N' ? 'AD' : transType,
        chargePoint: String(calculateCash(
          post.charCount,
          post.orgLang,
          post.transLang,
          calcOption
        ))
      },
    };
    // 내문서번역이면 reqIdx 추가
    if( post.transReqType === 'N' ) {
      chosen[post.fileIdx].transReqIdx = post.transReqIdx;
    }

    history.push({
      pathname: "/payment/orderPayment",
      query: {
        chosen,
        transReqType: post.transReqType
      },
    });
  };

  /**
   * 딤처리된 변리사검수 버튼 마우스오버 시 툴팁 열기
   * @param tooltip
   */
  handleMouseOver = (tooltip) => {
    if (document.getElementById(tooltip)) {
      document.getElementById(tooltip).style.visibility = "visible";
    }
  };

  /**
   * 딤처리된 변리사검수 버튼 마우스오버 시 툴팁 닫기
   * @param {*} tooltip
   */

  handleMouseLeave = (tooltip) => {
    if (document.getElementById(tooltip)) {
      document.getElementById(tooltip).style.visibility = "hidden";
    }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(
  TransHistoryContainer
);
